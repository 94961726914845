/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface RegistrationFailedResponse
 */
export interface RegistrationFailedResponse {
    /**
     * 
     * @type {string}
     * @memberof RegistrationFailedResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFailedResponse
     */
    code?: RegistrationFailedResponseCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RegistrationFailedResponseCodeEnum {
    MISSINGVALUE = 'MISSING_VALUE',
    INVALIDPERSONTYPE = 'INVALID_PERSON_TYPE',
    INVALIDUSERTYPE = 'INVALID_USER_TYPE',
    INVALIDEMAILADDRESS = 'INVALID_EMAIL_ADDRESS',
    INVALIDPASSWORD = 'INVALID_PASSWORD',
    EMAILREGISTERED = 'EMAIL_REGISTERED'
}

