import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["type", "id", "disabled"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_star_rating = _resolveComponent("in-star-rating")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_in_check_box_list_item = _resolveComponent("in-check-box-list-item", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: "item",
    id: _ctx.id
  }, [
    ('separator' === _ctx.item.type)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
      : (_ctx.item.type === 'checkbox')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            type: _ctx.item.type,
            id: _ctx.item.id,
            disabled: _ctx.loading,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.value) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }, null, 40, _hoisted_3)), [
            [_vModelDynamic, _ctx.data.value]
          ])
        : _createCommentVNode("", true),
    ('undefined' !== _ctx.item.type)
      ? (_openBlock(), _createElementBlock("label", {
          key: 2,
          for: _ctx.item.id
        }, [
          (_ctx.item.stars)
            ? (_openBlock(), _createBlock(_component_in_star_rating, {
                key: 0,
                value: _ctx.item.stars
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.item.title) + " ", 1),
          ('undefined' !== _ctx.item.help && _ctx.item.help)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "javascript:void(0);",
                title: _ctx.item.help
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "question-circle",
                  class: "help-icon"
                })
              ], 8, _hoisted_5))
            : _createCommentVNode("", true)
        ], 8, _hoisted_4))
      : _createCommentVNode("", true),
    ('undefined' !== _ctx.item.children && _ctx.item.children)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "children",
          ref: `child-${_ctx.item.id}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (item) => {
            return (_openBlock(), _createBlock(_component_in_check_box_list_item, {
              item: item,
              key: item.id,
              onInit: id => _ctx.onChildInit(item.id, id),
              modelValue: _ctx.data.children[item.id],
              "onUpdate:modelValue": ($event: any) => ((_ctx.data.children[item.id]) = $event),
              onChange: _ctx.onChildChange,
              loading: _ctx.loading
            }, null, 8, ["item", "onInit", "modelValue", "onUpdate:modelValue", "onChange", "loading"]))
          }), 128))
        ], 512))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}