<template>
    <simple-svg id="app-logo" :src="logo" :height="40"/>
</template>

<script>
import {defineComponent} from 'vue'

import logo from '@/assets/logo_2.svg'

export default defineComponent({
    setup() {
      return {
        logo
      }
    },
})
</script>

<style lang="scss">
#app-logo {
    margin-right: 20px;
    height: 40px;
    width: auto;
    transform: translateY(12px);
}
</style>

<docs>
```js
<app-logo/>
```
</docs>