import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_material_icon = _resolveComponent("material-icon")!
  const _component_in_input = _resolveComponent("in-input")!

  return (_openBlock(), _createBlock(_component_in_input, _mergeProps({
    class: "in-password-field",
    type: _ctx.type
  }, _ctx.$attrs, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
    ref: "field"
  }), {
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: "javascript:void(0);",
        class: "in-addon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
        tabindex: "-1",
        onfocus: "this.blur()"
      }, [
        _createVNode(_component_material_icon, {
          name: 'text' === _ctx.type ? 'visibility-off-outlined' : 'visibility-outlined',
          scale: 1.1
        }, null, 8, ["name"])
      ])
    ]),
    _: 1
  }, 16, ["type", "modelValue"]))
}