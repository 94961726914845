import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-checkbox-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_check_box_list_item = _resolveComponent("in-check-box-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config, (item) => {
      return (_openBlock(), _createBlock(_component_in_check_box_list_item, {
        item: item,
        key: item.id,
        modelValue: _ctx.data[item.id],
        "onUpdate:modelValue": ($event: any) => ((_ctx.data[item.id]) = $event),
        loading: _ctx.loading
      }, null, 8, ["item", "modelValue", "onUpdate:modelValue", "loading"]))
    }), 128))
  ]))
}