import mitt from 'mitt'

export const EVENT_VERIFIED = "VERIFIED"
export const EVENT_SESSION_EXPIRED = "SESSION_EXPIRED"
export const EVENT_SIGNED_IN = "SIGNED_IN"
export const EVENT_SIGN_IN_FAILED = "SIGN_IN_FAILED"
export const EVENT_PROFILE_COMPLETED = "PROFILE_COMPLETED"
export const EVENT_SIGNED_OUT = "SIGNED_OUT"
export const EVENT_LOCALE_UPDATED = "EVENT_LOCALE_UPDATED"

const emitter = mitt()

export default emitter
