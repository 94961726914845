/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface UserModelExtended
 */
export interface UserModelExtended {
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    address1?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    address2?: any;
    /**
     * 
     * @type {UserModelExtendedPhone}
     * @memberof UserModelExtended
     */
    phone?: any;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelExtended
     */
    isReady?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    id?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    personType?: UserModelExtendedPersonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    userType?: UserModelExtendedUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    username?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    sex?: UserModelExtendedSexEnum;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    title?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    firstName?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    surname?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    fullName?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    compname?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    position?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    zip?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    city?: any;
    /**
     * 
     * @type {string}
     * @memberof UserModelExtended
     */
    country?: any;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelExtended
     */
    mobileVerified?: any;
    /**
     * 
     * @type {Array&lt;UserModelExtendedImage&gt;}
     * @memberof UserModelExtended
     */
    image?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum UserModelExtendedPersonTypeEnum {
    User = 'user',
    Interpreter = 'interpreter'
}
/**
    * @export
    * @enum {string}
    */
export enum UserModelExtendedUserTypeEnum {
    Person = 'person',
    Comporg = 'comporg'
}
/**
    * @export
    * @enum {string}
    */
export enum UserModelExtendedSexEnum {
    M = 'm',
    F = 'f',
    D = 'd'
}

