import {createStore} from 'vuex'

import {AuthAction, AuthMutation, AuthStore} from "./auth/AuthStore";

import i18n from "@/plugins/i18n";

import {AuthApi, ConfigApi, ProfileApi, TranslationsApi} from "@/inpriton-client";
import {
    FrontendConfigurationLanguages,
    FrontendConfigurationLanguagesWebsite,
    Translations
} from "@/inpriton-client/models";
import globalAxios from "axios";
import {TokenService} from "@/plugins/service/TokenService";
import emitter, {EVENT_LOCALE_UPDATED} from "@/plugins/emitter";

export const Mutation = {
    SET_LOCALE: 'SET_LOCALE',
    SHOW_SIGN_IN: 'SHOW_SIGN_IN',
    SHOW_SIGN_UP: 'SHOW_SIGN_UP',
}

export interface State {
    initialized: boolean,
    home: boolean,
    locale: string,
    signInVisible: boolean,
    signUpVisible: boolean,
    activeCall: object|null,
    maxUploadSize: number|null,
    languages: FrontendConfigurationLanguages,
    translationsLoading: boolean,
    translations: Map<string, Translations>,
    routeAfterLogin: object|null,
    alert: string|null,
    confirmNavAway: object|null,
    activeHeaderDropdown: string|null,
    showProfileNotReadyAlert: boolean,
    showProfileCompletedAlert: boolean,
    sessionExpired: boolean
}

const translations: any = {}

for (const code of ['de-DE', 'en-US', 'en-UK']) {
    translations[code] = require(`@/translations/${code}.json`)
}

const store = createStore<State>({
    modules: {
        auth: AuthStore
    },

    state() {
        return {
            initialized: false,
            home: false,
            locale: 'en-UK',
            signInVisible: false,
            signUpVisible: false,
            activeCall: null,
            maxUploadSize: null,
            languages: {
                website: [],
                translations: []
            },
            translationsLoading: false,
            translations: new Map(),
            routeAfterLogin: null,
            alert: null,
            confirmNavAway: null,
            activeHeaderDropdown: null,
            showProfileNotReadyAlert: false,
            showProfileCompletedAlert: false,
            sessionExpired: false
        }
    },

    mutations: {
        SET_INITIALIZED(state) {
            if (state.translations.size < 1) {
                return setTimeout(() => {
                    // @ts-ignore
                    this.commit('SET_INITIALIZED')
                }, 100)
            }

            // @ts-ignore
            state.initialized = true

            // @ts-ignore
            setInterval(() => this.dispatch(`auth/${AuthAction.CHECK_SESSION}`), 15000)
        },

        SET_HOME(state, home: boolean) {
            state.home = home
        },

        SET_MAX_UPLOAD_SIZE(state, size: number) {
            state.maxUploadSize = size
        },

         [Mutation.SET_LOCALE] (state, locale: string) {
            if ('undefined' === typeof locale) {
                return
            }

            if ( ! state.translations.has(locale)) {
                // @ts-ignore
                this.dispatch('LOAD_TRANSLATIONS', locale)
            } else {
                state.locale = locale
                localStorage.setItem('app-locale', locale)
            }

            globalAxios.defaults.headers['accept-language'] = locale
        },

        SET_TRANSLATIONS_LOADING(state, loading: boolean) {
            state.translationsLoading = loading
        },

        SET_TRANSLATIONS(state, { locale, messages }) {
            i18n.global.setLocaleMessage(locale, messages)

            state.translations.set(locale, messages)

            // @ts-ignore
            this.commit(Mutation.SET_LOCALE, locale)
        },

        SET_ROUTE_AFTER_LOGIN(state, route: object|null = null) {
            state.routeAfterLogin = route
        },

        [Mutation.SHOW_SIGN_IN] (state, show: boolean = true) {
            state.signInVisible = show
        },

        [Mutation.SHOW_SIGN_UP] (state, show: boolean = true) {
            state.signUpVisible = show
        },

        SET_LANGUAGES(state, languages: FrontendConfigurationLanguages) {
            state.languages = languages
        },

        SET_ACTIVE_CALL(state, callData: object) {
            state.activeCall = callData
        },

        ALERT(state, message) {
            state.alert = message
        },

        CONFIRM_NAV_AWAY(state, route: object|null) {
            state.confirmNavAway = route
        },

        SET_HEADER_DROPDOWN(state, id: string|null) {
            state.activeHeaderDropdown = id
        },

        SHOW_PROFILE_NOT_READY_ALERT(state, show: boolean) {
            state.showProfileNotReadyAlert = show
        },

        SHOW_PROFILE_COMPLETED_ALERT(state, show: boolean) {
            state.showProfileCompletedAlert = show
        },
    },

    actions: {
        INITIALIZE ({ commit, dispatch }) {
            const accessToken = TokenService.getToken()

            // @ts-ignore
            const browserLanguage = navigator.language || navigator.userLanguage
            const savedLanguage = localStorage.getItem('app-locale')

            const locale = savedLanguage || browserLanguage

            commit(Mutation.SET_LOCALE, locale);

            (async () => {
                const configApi = new ConfigApi()
                const authApi = new AuthApi()

                const expectedResponses = accessToken ? 2 : 1
                let responses = 0

                configApi.getConfig(locale)
                    .then(({data}) => {
                        commit('SET_MAX_UPLOAD_SIZE', data.maxUploadSize)
                        commit('SET_LANGUAGES', data.languages)

                        const localeSearch = data.languages.website.filter((l: FrontendConfigurationLanguagesWebsite) => l.code === locale)

                        if (localeSearch.length < 1) {
                            commit(Mutation.SET_LOCALE, 'en-UK')
                        }

                        responses ++

                        if (responses === expectedResponses) {
                            commit('SET_INITIALIZED')
                        }
                    })

                if (accessToken) {
                    authApi.check()
                        .then(({data}) => {
                            commit(`auth/${AuthMutation.PROFILE}`, data.profile)

                            responses ++

                            if (responses === expectedResponses) {
                                commit('SET_INITIALIZED')
                            }
                        })
                        .catch(() => {
                            commit(`auth/${AuthMutation.SESSION_EXPIRED}`)

                            responses ++

                            if (responses === expectedResponses) {
                                commit('SET_INITIALIZED')
                            }
                        })
                }
            })()
        },

        LOAD_PROFILE({commit}) {
            const api = new ProfileApi()

            api.getProfile()
                .then(({data}) => commit('SET_PROFILE', data))
        },

        LOAD_TRANSLATIONS({ commit }, locale) {
            console.log('LOADING', locale);

            locale = locale === 'de' ? 'de-DE' : locale

            commit('SET_TRANSLATIONS_LOADING', true)

            commit('SET_TRANSLATIONS', { locale, messages: translations[locale] })
            commit('SET_TRANSLATIONS_LOADING', false)
            emitter.emit(EVENT_LOCALE_UPDATED)
        }
    }
})

// @ts-ignore
window.globalStore = store

const apiBaseURL = 'VUE_APP_API_URL' in process.env ? process.env.VUE_APP_API_URL : null

globalAxios.interceptors.request.use(config => {
    if (apiBaseURL) {
        config.baseURL = apiBaseURL
        config.url = config.url?.replace('https://i-api.loc.sfat.it', apiBaseURL)
    }

    const accessToken = TokenService.getToken()

    if (accessToken) {
        config.headers.set('Authorization', 'Bearer ' + accessToken)
    }
    config.headers.set('Content-Type', 'application/json')
    return config
})

export default store
