let worker = null
let send = null

if ('SharedWorker' in window) {
    worker = new SharedWorker('./shared-worker.ts', { type: 'module' })

    send = message => worker.port.postMessage({ message })
} else {
    console.log('shared worker not available')
}

export default { worker, send }
