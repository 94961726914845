import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "in-page-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.backgroundImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.backgroundImage
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.headline), 1),
          (_ctx.text)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "text",
                innerHTML: _ctx.text
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}