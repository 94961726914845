<template>
  <label v-if="label" :for="id" v-text="`${label !== null ? label : '&nbsp;'}${mandatory && editable ? ' *' : ''}`"></label>
  <label v-if="emptyLabel">&nbsp;</label>

  <div class="in-input" :class="{ 'no-placeholder': ! placeholder, 'has-error': editable && error, readonly }">
    <span v-if=" ! editable && 'select' !== type">{{ isDate ? formatDate(value) : value }}</span>

    <span v-else-if=" ! editable && 'select' === type">{{ options[value] || null }}</span>

    <input :type="type" v-bind="$attrs" v-model="value" :class="classnames" :id="id" ref="field" :mandatory="mandatory" :placeholder="htmlPlaceholder" :readonly="readonly" v-else-if="'select' !== type">

    <select v-bind="$attrs" v-model="value" :class="classnames" :id="id" ref="field" :mandatory="mandatory" :disabled="disabled || readonly" v-else>
      <option v-for="(name, option) in options" :key="option" :value="option" v-text="name"></option>
    </select>

    <span class="placeholder" v-if="placeholder" v-text="placeholder"></span>
    <slot></slot>

    <slot name="verfied"></slot>
  </div>
  <div class="error" v-if="editable && error" v-text="error"></div>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";
import {formatDate} from "@/functions";
import {computed, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  props: {
    type: { type: String, default: 'text' },
    label: { type: String, default: null },
    id: { type: String, default: null },
    options: { type: Object, default: () => ({}) },
    placeholder: { type: String, default: null },
    htmlPlaceholder: { type: String, default: null },
    mandatory: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    translate: { type: Boolean, default: false },
    noFullWidth: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    isDate: { type: Boolean, default: false },
    emptyLabel: { type: Boolean, default: false },
    error: { type: String, required: false },
    modelValue: { type: String, required: true }
  },

  emits: ['update:modelValue'],

  displayName: 'in-input',

  setup(props, { emit }) {
    const {t} = useI18n()

    const field = ref<HTMLInputElement|null>(null)
    const value = ref<string>(props.translate ? t(props.modelValue) : props.modelValue)

    watch(value, () => {
      emit('update:modelValue', value.value)
    })

    watch(() => props.modelValue, () => {
      value.value = props.modelValue
    })

    const classnames = computed(() => {
      const res = []
      if (value.value?.length > 0 || value.value) {
        res.push('has-data')
      }

      if (props.noFullWidth) {
        res.push('no-full-width')
      }

      return res
    })

    const focus = () => {
      field.value?.focus()
    }

    return {
      value,
      field,
      focus,
      classnames,
      formatDate
    }
  }
})
</script>

<style lang="scss">
label {
  display: block;
  white-space: nowrap;
}
</style>

<docs>
```js
<in-input label="Label" placeholder="Hello World"/>
```
</docs>