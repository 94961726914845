import { inject, provide } from 'vue'
import {BASE_PATH} from "@/inpriton-client/base";
import globalAxios from "axios";

export class WsNotifyClient {
    private ws: WebSocket | undefined;

    private listeners: {id: string, callbackConnect: () => void, callbackMessage: (message: MessageEvent) => void}[] = []
    private sw: ServiceWorkerRegistration|undefined;
    private username: string|undefined;

    constructor() {
        this.connect()
    }

    notify(title: string, options: NotificationOptions) {
        if ( ! ('icon' in options)) {
            options.icon = './img/icons/favicon-96x96.png'
        }

        if ( ! ('vibrate' in options)) {
            options.vibrate = [200, 100, 200, 100, 200, 100, 200]
        }

        if (this.sw) {
            console.log('show notify with service worker', title)
            this.sw.showNotification(title, options)
        } else {
            console.log('show native notify', title)
            new Notification(title, options)
        }
    }

    connect() {
        const apiBaseURL = 'VUE_APP_API_URL' in process.env ? process.env.VUE_APP_API_URL : null

        let wsUrl = BASE_PATH
        if (apiBaseURL) {
            wsUrl = apiBaseURL
        }

        this.ws = new WebSocket(wsUrl.replace('https', 'wss') + '/ws/notify')

        this.ws.addEventListener('open', () => {
            console.log('websocket connection opened')

            this.listeners.forEach(l => l.callbackConnect())
        })

        this.ws.addEventListener('close', () => {
            console.log('websocket connection closed - reconnect')
            this.connect()
        })

        this.ws.addEventListener('error', (err) => {
            console.log('websocket connection error', err)
        })

        this.ws.addEventListener('message', (message) => {
            console.log('ws-notify', 'got message', message, this.listeners)
            this.listeners.forEach(l => l.callbackMessage(message))
        })
    }

    login(username: string) {
        this.username = username

        this.send(`login:${username}`)
    }

    send(message: string): void {
        if (this.ws?.readyState !== 1) {
            setTimeout(() => this.send(message), 100)
        } else {
            this.ws?.send(message)
        }
    }

    register(id: string, callbackConnect: () => void, callbackMessage: (message: MessageEvent) => void) {
        this.listeners.push({id, callbackConnect, callbackMessage})
    }

    unregister(id: string) {
        this.listeners = this.listeners.filter(l => l.id !== id)
    }
}

export function provideClient(): WsNotifyClient {
    const client = new WsNotifyClient()

    provide<WsNotifyClient>('ws-notify-client', client)

    return client
}

export function useNotifyClient(): WsNotifyClient {
    const client = inject<WsNotifyClient>('ws-notify-client')

    if ( ! client) {
        throw new Error('ws notify client not provided')
    }

    return client
}
