<template>
  <header class="in-page-header">
    <img v-if="backgroundImage" :src="backgroundImage">
    <div class="content">
      <div class="inner">
        <div class="container">
          <h1>{{ headline }}</h1>

          <div class="text" v-if="text" v-html="text"></div>

          <slot></slot>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
  props: {
    headline: { type: String, required: true },
    text: String,
    backgroundImage: String,
  },
})
</script>

<style lang="scss">
@import '@/scss/mixin/container';

.in-page-header {
  min-height: calc(100vh - 64px);
  margin-left: -20px;
  position: relative;

  & > img {
    width: 100%;
    margin-top: -60px;
  }

  .content {
    position: absolute;
    top: 0;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(10px);
  }

  .inner {
    @include container;
    height: 100%;
    width: 100%;

    .container {
      width: 55%;

      h1 {
        font-size: 60px;
      }

      .text {
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .inner .container {
      h1 { font-size: 50px; }
      .text { font-size: 18px; }
    }
  }
  @media screen and (max-width: 1200px) {
    .inner .container {
      h1 { font-size: 45px; }
    }
    & > img { transform: translateX(20%); }
    .inner .container { width: 70%; }
  }
  @media screen and (max-width: 950px) {
    & > img { display: none; }
    .inner .container { width: 100%; }

    background: linear-gradient(0, #d0e5f9, transparent 50%, transparent);
    min-height: unset;
    padding: 50px 0;

    .content {
      position: inherit;
      min-height: unset;
    }
  }
  @media screen and (max-width: 720px) {
    .inner .container {
      h1 { font-size: 40px; }
    }
  }
  @media screen and (max-width: 560px) {
    .inner .container {
      h1 { font-size: 30px; }
      .text { font-size: 16px; line-height: 21px; }
    }
  }
  @media screen and (max-width: 450px) {
    .inner .container {
      h1 { font-size: 24px; }
    }
  }
}
</style>
