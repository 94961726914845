<template>
  <ul class="icon-text-boxes" :class="classes">
    <li v-for="c of config" :key="c.title" :class="{ 'has-button': c.route || c.action }">
      <div class="icon">
        <svg-icon :name="c.icon" v-if="c.icon"></svg-icon>
        <img :src="c.image" v-if="c.image">
      </div>
      <div class="content">
        <div class="headline" v-text="c.title"></div>
        <div class="body" v-text="c.text" v-if="c.text"></div>
        <div class="body" v-if="c.list">
          <in-ul :items="c.list"></in-ul>
        </div>
        <div class="button-container" v-if="c.route || c.action">
          <in-button @click="c.action" v-if="c.action" v-text="c.buttonLabel || t('find_out_more')"/>
          <router-link :to="c.route" class="in-button" v-else-if="c.route" v-text="c.buttonLabel || t('find_out_more')"></router-link>
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import {RouteRecord} from "vue-router";
import {useI18n} from "vue-i18n";
import SvgIcon from "@/plugins/material-icons/SvgIcon.vue";
import {computed} from "vue";
import InButton from "@/components/general/InButton.vue";
import {defineComponent} from "@vue/runtime-core";
import InUl from "@/components/general/InUl.vue";

interface TextBoxConfig {
  /** imported SVG icon */
  icon?: string
  /** normal image instead of svg icon */
  image?: any
  title: string
  text?: string
  list?: string[]
  /** route for button click */
  route?: RouteRecord
  buttonLabel?: string
  /** button callback */
  action?: () => void
}

export default defineComponent({
  components: {InButton, SvgIcon, InUl},
  props: {
    config: {
      type: Array as () => TextBoxConfig[]
    },
    noShadow: {
      type: Boolean,
      defaultValue: false,
    },
    center: {
      type: Boolean,
      defaultValue: false,
    },
    iconLeft: {
      type: Boolean,
      defaultValue: false,
    },
    mobileVertical: {
      type: Boolean,
      defaultValue: false,
    },
  },

  setup(props) {
    const {t} = useI18n()

    const classes = computed(() => {
      const c: string[] = []
      if (props.noShadow) {
        c.push('no-shadow')
      }
      if (props.iconLeft) {
        c.push('icon-left')
      }
      if (props.center) {
        c.push('center')
      }
      if (props.mobileVertical) {
        c.push('mobile-vertical')
      }
      return c
    })

    return {
      t,
      classes,
    }
  }
})
</script>

<style lang="scss">
.icon-text-boxes {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 32px 0 0;

  &:not(.no-shadow) > li {
    box-shadow: 0 0 10px 0 #bdbdbd;
  }

  &.no-shadow {
    grid-gap: 60px;
  }

  &.icon-left > li {
    display: flex;
    padding-left: 0;
    padding-right: 0;

    .icon {
      padding-right: 16px;
    }

    .headline {
      margin-top: 0;
    }
  }

  & > li {
    padding: 20px 20px 100px;
    position: relative;
    font-size: 14px;

    .button-container {
      position: absolute;
      bottom: 20px;
    }

    svg {
      fill: var(--base1);
      width: 60px;
      height: 60px;
    }

    .headline {
      font-weight: bold;
      margin: 16px 0 20px;
      font-size: 18px;
    }
  }

  &.center > li {
    text-align: center;
  }

  &.mobile-vertical {
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-gap: 20px !important;

      & > li:not(.has-button) {
        padding-bottom: 0;
      }
    }
  }

  &:not(.mobile-vertical) {
    @media screen and (max-width: 760px) {
      overflow-x: auto;
      padding: 20px;
      margin-left: -20px;
      width: calc(100% + 40px);

      & > li {
        width: calc(100vw - 100px);
        min-width: 270px;
      }
    }
  }
}
</style>

<docs>
### Default

```js
const image = require('@/assets/icons/Icon_Monitor with browser.svg')

<icon-text-boxes :config="[
  {
    image,
    title: 'Box with list',
    list: [
      'Kundenportal',
      'sichere Anmeldung',
      'hochwertiges Video',
      'kristallklarer Ton',
      'Cross-Platform Kompatibilität',
      'benutzerfreundliche Oberfläche',
    ]
  },
  {
    image,
    title: 'Box with Text',
    text: 'Hello World'
  },
  {
    image,
    title: 'Box with Button',
    text: 'Hello World',
    buttonLabel: 'Hello World',
    action: () => null,
  }
]"/>
```

### Centered

```js
const image = require('@/assets/icons/Icon_Monitor with browser.svg')

<icon-text-boxes center :config="[
  {
    image,
    title: 'Box with list',
    list: [
      'Kundenportal',
      'sichere Anmeldung',
      'hochwertiges Video',
      'kristallklarer Ton',
      'Cross-Platform Kompatibilität',
      'benutzerfreundliche Oberfläche',
    ]
  },
  {
    image,
    title: 'Box with Text',
    text: 'Hello World'
  },
  {
    image,
    title: 'Box with Button',
    text: 'Hello World',
    buttonLabel: 'Hello World',
    action: () => null,
  }
]"/>
```
</docs>