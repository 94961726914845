<template>
  <in-layer class="in-login" :width="400" :loading="loading" :class="{ register: showRegister }" v-if=" ! registered" close-on-outside-click>

    <ul class="tabs">
      <li>
        <a href="javascript:void(0);" :class="{ inactive: personType !== PersonType.User }" @click="personType = PersonType.User">
          {{t('for_customers')}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" :class="{ inactive: personType !== PersonType.Interpreter }" @click="personType = PersonType.Interpreter">
          {{t('for_interpreters')}}
        </a>
      </li>
    </ul>

    <div v-if="formMode === SignInFormModes.SIGN_IN">
      <h2>{{ t('sign_in') }}</h2>

      <form action="" @submit.prevent="signIn" v-if="formMode === SignInFormModes.SIGN_IN">
        <in-input type="email" v-model="email" ref="autofocus" :disabled="loading" :placeholder="t('email')" @keyup.enter.prevent="signIn" required/>
        <in-password-field v-model="password" :disabled="loading" :placeholder="t('password')" @keyup.enter.prevent="signIn" />

        <div class="error" v-show="errLogin" v-text="errLogin"></div>

        <in-button type="submit" v-text="t('sign_in')" :disabled="loading || ! submitAvailable" @click="signIn"></in-button>
      </form>

      <div class="footer left col-2">
        <div>
          <label class="checkbox-container" for="remember">Stay signed in
              <input type="checkbox" id="remember">
              <span class="regular-checkbox"></span>
          </label>
        </div>
        <div class="footer hover">
          <a href="javascript:void(0);">Forgot Password?</a>
        </div>
      </div>

      <in-hr/>

      <div class="footer">
        <span>{{ t('dont_have_account_yet') }}</span>
        <a class="footer hover add-padding-left" href="javascript:void(0);" @click="formMode = SignInFormModes.SIGN_UP">{{ t('sign_up') }}</a>
      </div>
    </div>

    <div v-if="formMode === SignInFormModes.SIGN_UP">
      <h2>{{ t('sign_up') }}</h2>

      <form action="" @submit.prevent="signUp" v-if="formMode === SignInFormModes.SIGN_UP">
        <in-select id="userType" v-if="personType === PersonType.User" v-model="userType" disable-null :placeholder="t('signing_up_as')" :options="{ [UserType.Individual]: t(UserType.Individual), [UserType.Comporg]: t(UserType.Comporg) }"/>
        <in-input type="email" v-model="email" :disabled="loading" :placeholder="t('email')" @keyup.enter.prevent="signUp"/>
        <in-password-field v-model="password" :disabled="loading" :placeholder="t('password')" @keyup.enter.prevent="signUp"/>

        <div class="error" v-if="errRegister" v-text="errRegister"></div>

        <in-button type="submit" v-text="t('sign_up')" :disabled="loading || ! submitAvailable" @click="signUp"></in-button>

        <div class="footer left tos_agreement">
          <i18n-t keypath="tos_agreement" tag="span">
            <template v-slot:terms_and_conditions>
              <router-link :to="{ name: 'home' }" target="_blank" class="footer hover">{{ t('terms_and_conditions') }}</router-link>
            </template>
            <template v-slot:privacy_policy>
              <router-link :to="{ name: 'home' }" target="_blank" class="footer hover">{{ t('privacy_policy') }}</router-link>
            </template>
          </i18n-t>
        </div>
      </form>

      <in-hr/>

      <div class="footer">
        <span class="dark light">{{ t('already_have_account') }}</span>
        <a class="footer hover add-padding-left" href="javascript:void(0);" @click="formMode = SignInFormModes.SIGN_IN">{{ t('sign_in') }}</a>
      </div>
    </div>
  </in-layer>

  <in-layer class="in-registered-layer" :width="600" :loading="resending" @close="store.commit('auth/SIGNED_UP', false)" v-else>
    <strong>{{ t('signed_up.headline') }}</strong>

    <p v-html="t('signed_up.description')"></p>

<!--    <button class="btn-resend" :disabled="resending" @click="resend">-->
<!--      <font-awesome-icon icon="envelope" v-if=" ! resending"/>-->
<!--      <font-awesome-icon icon="spinner" spin v-else/>-->
<!--      {{ t('signed_up.resend_email') }}-->
<!--    </button>-->
  </in-layer>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, ref} from "@vue/runtime-core";
import {useI18n} from "vue-i18n";
import InLayer from "./InLayer.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import InHr from "@/components/general/InHr.vue";
import InPasswordField from "@/components/general/InPasswordField.vue";
import {watch} from "vue";
import InInput from "@/components/general/InInput.vue";
import {
  SignInRequest,
  SignUpRequest, SignUpRequestPersonTypeEnum, SignUpRequestUserTypeEnum
} from "@/inpriton-client/models";
import InSelect from "@/components/general/InSelect.vue";
import {AuthAction, AuthMutation} from "@/plugins/store/auth/AuthStore";
import emitter, {EVENT_SIGN_IN_FAILED, EVENT_SIGNED_IN} from "@/plugins/emitter";
import InButton from "@/components/general/InButton.vue";

export enum SignInFormModes {
  // eslint-disable-next-line no-unused-vars
  SIGN_IN = 'signin',
  // eslint-disable-next-line no-unused-vars
  SIGN_UP = 'signup'
}

export default defineComponent({
  components: {InButton, InSelect, InInput, InPasswordField, InHr, InLayer },

  props: {
    mode: { type: String, required: true }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const formMode = ref<string>(props.mode)

    const autofocus = ref<HTMLInputElement|null>(null)

    const email = ref<string>('')
    const password = ref<string>('')

    const loading = computed<boolean>(() => store.state.auth.authLoading)
    const registered = computed<boolean>(() => store.state.auth.signedUp)
    const errRegister = computed<string|null>(() => store.state.auth.signUpError)

    const showRegister = ref<boolean>(false)
    const resending = ref<boolean>(false)

    const errLogin = ref<string|null>()

    const personType = ref<SignUpRequestPersonTypeEnum>(SignUpRequestPersonTypeEnum.User)
    const userType = ref<SignUpRequestUserTypeEnum|null>(null)

    const { t } = useI18n()

    const submitAvailable = computed(() => {
      if (password.value.length < 8) {
        return false;
      }

      if (email.value.length < 8) {
        return false;
      }

      if (
          formMode.value === SignInFormModes.SIGN_UP
          && personType.value === SignUpRequestPersonTypeEnum.User
          && ! userType.value
      ) {
        return false;
      }

      return true
    })

    emitter.on(EVENT_SIGNED_IN, () => {
      if (store.state.routeAfterLogin) {
        router.push(store.state.routeAfterLogin)
        store.commit('SET_ROUTE_AFTER_LOGIN', null)
      }
    })

    emitter.on(EVENT_SIGN_IN_FAILED, () => {
      errLogin.value = t('sign_in_failed')
    })

    const loginData = computed<SignInRequest>(() => ({
      username: email.value.trim(),
      password: password.value.trim()
    }))

    const signIn = () => {
      if ( ! submitAvailable.value) {
          return
      }

      errLogin.value = null

      console.log('dispatch')
      store.dispatch(`auth/${AuthAction.SIGN_IN}`, loginData.value)
    }

    const signUp = () => {
      if ( ! submitAvailable.value) {
          return
      }

      const request: SignUpRequest = {
        personType: personType.value,
        email: email.value,
        password: password.value
      }

      if (personType.value === SignUpRequestPersonTypeEnum.User) {
        request.userType = userType.value || undefined
      }

      store.dispatch(`auth/${AuthAction.SIGN_UP}`, request)
    }

    const resend = () => {
      resending.value = true

      setTimeout(() => {
        resending.value = false
      }, 2000)
    }

    watch(loginData, () => errLogin.value = null)
    watch(email, () => store.commit(`auth/${AuthMutation.ERROR_SIGN_UP}`, null))

    onMounted(() => {
      if (document.body.getBoundingClientRect().width > 700) {
        autofocus.value?.focus()
      }
    })

    watch([formMode, personType, userType], () => {
      nextTick(() => {
        if (document.body.getBoundingClientRect().width > 700) {
          autofocus.value?.focus()
        }
      })
    }, {deep: true})

    return {
      store,
      PersonType: SignUpRequestPersonTypeEnum,
      UserType: SignUpRequestUserTypeEnum,
      password,
      email,
      submitAvailable,
      loading,
      signIn,
      signUp,
      autofocus,
      showRegister,
      registered,
      errLogin,
      errRegister,
      resending,
      resend,
      t,
      personType,
      userType,
      formMode,
      SignInFormModes
    }
  }

})
</script>

<style lang="scss">
.in-login {
  text-align: center;

  .customer-type-selection {
    margin: 10px 0 30px;
  }

  color: var(--dark2);
  line-height: inherit;

  h2 {
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 16px;
    color: var(--dark1);
  }

  button {
    padding: 16px;
    margin-bottom: 12px !important;
  }

  .in-hr {
    margin-top: 32px;
  }

  .accordion-link {
    color: var(--base1);
    text-decoration: none;
  }

  &.register {
    .accordion-content.login {
      transform: scaleY(0);
    }
  }

  .chevron {
    transform: translateY(1px) rotate(0);
    transition: transform .5s;

    &.up {
      transform: translateY(1px) rotate(180deg);
    }
  }

  .footer {
    font-size: 14px;
    line-height: 20px;

    &.left {
      text-align: left;
    }

    span {
      display: inline-block;
      /*margin-right: 8px;*/
      text-align: center;
    }

    &.col-2, &.tos_agreement {
      margin-bottom: 32px;
    }

    &.col-2 {
      display: flex;
      width: 100%;

      & > div {
        flex: 1;
      }

      & > div:nth-child(2) {
        text-align: right;

        a {
          color: var(--base2);
        }
      }
    }

    &.hover:hover {
      text-decoration: underline;
    }

    &.add-padding-left {
      padding-left: 8px;
    }
  }

  .headline {
    position: absolute;
    left: 15px;
    font-weight: bold;
    top: 10px;
  }

  .buttons {
    text-align: center;
    display: flex;
    width: 90%;
    margin: 0 auto;

    button, .button {
      margin-bottom: -10px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  input:not([type=checkbox]), button {
    display: block;
    margin: 16px 0;
    width: 100%;
  }
}

.in-registered-layer {
  text-align: center;
  color: var(--grey);

  strong {
    color: #000;
    font-size: 18px;
  }

  .btn-resend {
    width: auto !important;
    margin: 0 auto !important;
  }
}
</style>
