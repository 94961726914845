import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "header" }
const _hoisted_4 = {
  key: 0,
  ref: "elList"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "timestamp" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "notification empty" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_material_icon = _resolveComponent("material-icon")!
  const _component_in_header_dropdown = _resolveComponent("in-header-dropdown")!

  return (_openBlock(), _createBlock(_component_in_header_dropdown, {
    id: "notifications",
    class: _normalizeClass(["in-notification-area", { notify: _ctx.notify }]),
    empty: _ctx.unreadNotificationCount < 1,
    width: 400,
    "max-height": _ctx.maxHeight,
    onOpen: _ctx.onOpen,
    onClose: _ctx.onClose
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, { icon: "bell" }),
        (_ctx.unreadNotificationCount)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "unread-count",
              textContent: _toDisplayString(_ctx.unreadNotificationCount)
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ])
    ]),
    dropdown: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.t('notification', 2)) + " ", 1),
        _createVNode(_component_router_link, {
          class: "settings",
          to: { name: 'notifications' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_svg_icon, { name: "settings_outline" })
          ]),
          _: 1
        })
      ]),
      (_ctx.notifications.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (n) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["notification", { seen: !! n.seen }]),
                key: n.id
              }, [
                _createElementVNode("div", {
                  class: "content",
                  innerHTML: n.content
                }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.timeSince(n.created)), 1)
              ], 2))
            }), 128))
          ], 512))
        : (_openBlock(), _createElementBlock("ul", _hoisted_7, [
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_material_icon, {
                name: "notifications-active-outlined",
                scale: "5"
              }),
              _createElementVNode("div", {
                innerHTML: _ctx.t('no_notifications_message')
              }, null, 8, _hoisted_9)
            ])
          ]))
    ]),
    _: 1
  }, 8, ["class", "empty", "max-height", "onOpen", "onClose"]))
}