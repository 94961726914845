<template>
    <span class="star-rating">
        <font-awesome-icon :icon="[value ? 'fas' : 'far', 'star']" v-for="(value, name) in config" :key="name"/>
    </span>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
    props: {
        stars: { type: Number, default: 5 },
        value: { type: Number, required: true }
    },

    setup(props) {
        const config = {}
        for (let i = 1; i <= props.stars; i++) {
            config[`star-${i}`] = i <= props.value
        }

        return {
            config
        }
    }
})
</script>

<style lang="scss">
.star-rating {
    display: inline-block;
    transform: translateY(-3px);

    svg {
        height: 10px;
        width: 12px !important;
    }
}
</style>

<docs>
```js
<in-star-rating :value="3"/>
```
</docs>