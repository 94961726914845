<template>
  <router-link :to="route" :class="classNames" v-if="route">
    <slot></slot>
  </router-link>
  <button :class="classNames" ref="buttonRef" @click="onClick" v-else>
    <slot></slot>
  </button>

</template>

<script lang="ts">
import {computed, defineComponent} from "@vue/runtime-core";
import {RouteParams} from "vue-router";
import {PropType, ref} from "vue";


export default defineComponent({
  components: {
  },

  props: {
    outline: { type: Boolean, default: false },
    noUpperCase: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    route: { type: Object as PropType<RouteParams>, default: null },
    disabled: { type: Boolean, default: false },
  },

  emits: ['click'],

  setup(props, { emit }) {
    const buttonRef = ref<HTMLButtonElement|null>(null)

    const classNames = computed(() => {
      const res = ['in-button']
      if (props.outline) {
        res.push('outline')
      }
      if (props.noUpperCase) {
        res.push('no-upper-case')
      }
      if (props.large) {
        res.push('large')
      }
      if (props.filled) {
        res.push('filled')
      }
      if (props.disabled) {
        res.push('disabled')
      }
      return [
        'in-button',
        'button',
        ...res
      ]
    })

    const onClick = (e: any) => {
      emit('click', e)
    }

    const focus = () => buttonRef.value?.focus()

    return {
      buttonRef,
      classNames,
      onClick,
      focus,
    }
  }
})
</script>

<style lang="scss">
@import '@/scss/vars';

.in-button {
  background: #fff;
  text-transform: none;
  border: 1px solid var(--base1);
  color: var(--base1);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;

  &:hover {
    color: #fff;
    background: var(--base1);
  }

  &.large {
    padding: 16px 32px;
    font-size: 18px;
    border-radius: 32px;
  }

  &.filled {
    background: var(--base1);
    color: #fff;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  @media screen and (max-width: 460px) {
    font-size: 14px;
    padding: 6px 14px;

    &.large {
      font-size: 16px;
      padding: 14px 30px;
    }
  }
}
</style>

<docs>
### Default button

```js
<in-button>Hello World</in-button>
```

### Filled button

```js
<in-button filled>Hello World</in-button>
```

### Large buttons

```js
<in-button large>Hello World</in-button>
<in-button large filled>Hello World</in-button>
```
</docs>