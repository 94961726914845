<template>
  <div class="in-layer" :class="{ 'no-backdrop': noBackdrop}">
    <div class="background"></div>

    <div class="scroller">
      <div class="layer" :class="{ 'no-jump': noJump }" :style="style" ref="layer">
        <div class="header">
          <a href="javascript:void(0);" @click="emitClose" class="link-close" v-if="$props.closeable && ! $props.closeOnOutsideClick && ! noClose && ! loading">
            <font-awesome-icon icon="times"/>
          </a>
          <a href="javascript:void(0);" class="link-close loading" v-else-if=" ! noClose && loading">
            <font-awesome-icon icon="spinner" spin/>
          </a>
        </div>
        <div class="content" :class="{ 'has-headline': hasHeadline }">
          <h2 class="headline" ref="headline">
            <slot name="headline"></slot>
          </h2>
          <slot></slot>
        </div>
        <slot name="buttons" class="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, onMounted} from "@vue/runtime-core";
import {ref} from "vue";

interface LayerStyle {
  width?: string
}

export default defineComponent({
  props: {
    width: {type: Number, default: 0},
    loading: {type: Boolean, default: false},
    noClose: {type: Boolean, default: false},
    noBackdrop: {type: Boolean, default: false},
    noJump: {type: Boolean, default: false},
    closeOnOutsideClick: {type: Boolean, default: false},
    closeable: {type: Boolean, default: true}
  },

  setup(props, {emit}) {
    const layer = ref<HTMLElement|null>(null)
    const headline = ref<HTMLElement|null>(null)
    const hasHeadline = ref<boolean>(false)

    const emitClose = () => {
      emit('close')
    }

    const style = computed<LayerStyle>(() => {
      const style: LayerStyle = {}

      if (props.width) {
        style.width = `${props.width}px`
      }

      return style
    })

    const onClick = (e: Event) => {
      // @ts-ignore
      if (e.target.classList.contains('scroller') || e.target.classList.contains('background')) {
        if (props.closeOnOutsideClick) {
          return emitClose()
        }

        layer.value?.classList.add('jump')

        setTimeout(() => layer.value?.classList.remove('jump'), 300)
      }
    }

    onMounted(() => {
      document.body.classList.add('layer-open')
      document.body.addEventListener('click', onClick)

      hasHeadline.value = !! (headline.value?.innerText.trim().length || false)
    })

    onBeforeUnmount(() => {
      document.body.classList.remove('layer-open')
      document.body.removeEventListener('click', onClick)
    })

    return {
      style,
      emitClose,
      layer,
      headline,
      hasHeadline
    }
  }
})
</script>

<style lang="scss">
@keyframes jump {
  0% { transform: translateY(-50%) scale(1); }
  50% { transform: translateY(-50%) scale(1.03); }
  100% { transform: translateY(-50%) scale(1); }
}

.in-layer {
  z-index: 998;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  color: var(--dark1);
  line-height: 25px;

  .background {
    background: #000;
    opacity: .5;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }

  &.no-backdrop .background {
    background: none;
  }

  .scroller {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    padding-bottom: 60px;
  }

  .layer {
    background: #fff;
    padding: 32px;
    margin: 50vh auto auto;
    transform: translateY(-50%);
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    max-height: 90vh;
    overflow-y: auto;

    max-width: 90vw;

    &:not(.no-jump).jump {
      animation: jump .3s;
    }

    .link-close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: rgb(27, 27, 27);
      padding: 5px;

      svg {
        width: 16px;
        color: var(--dark1);
      }

      &.loading {
        cursor: default;
      }

      &:hover svg {
        transform: scale(1.2);
        color: var(--base1);
      }
    }
  }

  .content.has-headline {
    margin-top: 10px;
  }

  ul.tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;

    li {
      display: inline-block;
      position: relative;
      flex: 1;

      a {
        display: block;
        font-size: 20px;
        font-weight: 500;
        padding: 16px 5px;
        color: var(--base1);
        border-bottom: 2px solid var(--base1);

        &.inactive {
          color: var(--dark3);
          border-bottom: 1px solid var(--dark4);
          &:hover {
            color: var(--base1);
          }
        }
      }

      &:not(:last-child):after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 50%;
        background: var(--dark4);
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(50%);
      }
    }
  }

  .headline {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 16px;
  }

  .buttons {
    margin: 40px 0 0;

    button, .button {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
</style>

<docs>
```js
import { ref } from 'vue'

const show = ref(false)

<in-layer @close="() => show = false" :width="600" v-if="show">
  <template #headline>
    Layer title
  </template>

  Content

  <template #buttons>
    <in-button @click="() => show = false">Close</in-button>
  </template>
</in-layer>

<button @click="() => show = true">show</button>
```
</docs>