import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id", "disabled"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["value", "textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["in-select", {'no-placeholder': ! _ctx.$props.placeholder}])
  }, [
    _withDirectives(_createElementVNode("select", {
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
      disabled: _ctx.disabled,
      class: _normalizeClass({ 'has-data': !! _ctx.selectedValue })
    }, [
      ( ! _ctx.disableNull)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            value: null,
            textContent: _toDisplayString(_ctx.nullValue)
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsObject, (value, key) => {
        return (_openBlock(), _createElementBlock("option", {
          key: key,
          value: key,
          textContent: _toDisplayString(value)
        }, null, 8, _hoisted_3))
      }), 128))
    ], 10, _hoisted_1), [
      [_vModelSelect, _ctx.selectedValue]
    ]),
    (_ctx.$props.placeholder)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "placeholder",
          textContent: _toDisplayString(_ctx.$props.placeholder)
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}