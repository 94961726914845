<template>
  <div class="in-hr">
    <span><slot></slot></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
})
</script>

<style lang="scss">
.in-hr {
  width: 100%;
  height: 2px;
  border-style: solid;
  border-color: var(--dark4);
  border-width: 1px 0 0;
  margin: 16px 0;

  span {
    color: var(--dark2);
    display: inline-block;
    background: #fff;
    transform: translateY(-11px);
    padding: 0 5px;
  }
}
</style>

<docs>
```js
<in-hr/>
```
</docs>