/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface SMSVerificationFailedResponse
 */
export interface SMSVerificationFailedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SMSVerificationFailedResponse
     */
    success?: any;
    /**
     * 
     * @type {string}
     * @memberof SMSVerificationFailedResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof SMSVerificationFailedResponse
     */
    code?: SMSVerificationFailedResponseCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SMSVerificationFailedResponseCodeEnum {
    NOTFOUND = 'NOT_FOUND',
    NOTALLOWED = 'NOT_ALLOWED',
    TIMEOUT = 'TIMEOUT',
    ALREADYCOMPLETED = 'ALREADY_COMPLETED',
    INVALIDSOLUTION = 'INVALID_SOLUTION'
}

