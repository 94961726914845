<template>
  <footer class="in-footer">
      <div class="inner">
          <div class="col">
              <div class="title">CONTACT</div>
              <a href="tel:+43 345 678 903">+43 345 678 903</a><br>
              <a href="mailto:support@inpriton.com">support@inpriton.com</a>
          </div>
          <div class="col">
              <div class="title">SERVICES</div>
          </div>
          <div class="col">
              <div class="title">COMPANY</div>
              <a href="javascript:void(0);">About us</a><br>
              <a href="javascript:void(0);">Work with us</a><br>
              <a href="javascript:void(0);">Impressum</a>
          </div>
          <div class="col">
              <div class="title">INFORMATION</div>
              <a href="javascript:void(0);">Privacy policy</a><br>
              <a href="javascript:void(0);">Terms and conditions</a>
          </div>
          <div class="col">
              <ul class="social-icons">
                  <li><a href="javascript:void(0)"><font-awesome-icon :icon="['fab', 'instagram']"/></a></li>
                  <li><a href="javascript:void(0)"><font-awesome-icon :icon="['fab', 'twitter']"/></a></li>
                  <li><a href="javascript:void(0)"><font-awesome-icon :icon="['fab', 'facebook-f']"/></a></li>
              </ul>

              &copy; {{ (new Date()).getFullYear() }} Inpriton
          </div>
      </div>
  </footer>
</template>

<style lang="scss">
footer.in-footer {
    margin-top: 120px;
    padding: 50px 40px 70px;
    box-shadow: 0 0 10px 0 #bdbdbd;

    & > .inner {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        @media screen and (max-width: 1050px) {
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media screen and (max-width: 870px) {
            grid-template-rows: repeat(3, 1fr);
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 520px) {
            grid-template-columns: 1fr;

            .col {
                text-align: center;
            }

            .social-icons {
                justify-content: center;
            }
        }

        .col {
            margin-top: 20px;
        }

        &, * {
            color: var(--grey);
        }

        a:hover {
            text-decoration: underline;
        }

        .title {
            margin-bottom: 10px;
        }
    }

    .social-icons {
        list-style-type: none;
        margin: 0 0 30px;
        padding: 0;
        display: flex;

        li {
            padding: 0 10px;
        }
    }
}
</style>
<script setup lang="ts">
</script>
