import state from './state'
import actions, {AuthAction} from './actions'
import mutations, {AuthMutation} from './mutations'

export {AuthAction, AuthMutation}

export const AuthStore = {
    namespaced: true,
    state,
    mutations,
    actions
}
