<template>
  <div class="in-user-avatar" :title="store.state.auth.profile.fullName" :style="style">
    <span class="hover">{{ profileImage ? '&nbsp;' : initials }}</span>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import {UserModelExtended} from "@/inpriton-client/models";

export default defineComponent({
  props: {
    // @ts-ignore
    user: { type: Object as UserModelExtended, required: true }
  },

  setup() {
    const store = useStore();

    const profile = computed<UserModelExtended|null>(() => store.state.auth.profile)

    const profileImage = computed(() => {
      // @ts-ignore
      const res = profile.value?.image?.filter(i => i.size === 'thumb-square-100')

      return res ? res[0] : null
    })

    const style = computed(() => {
      const res = {}

      if (profileImage.value) {
        // @ts-ignore
        res['backgroundImage'] = `url(${profileImage.value.url})`
        // @ts-ignore
        res['border'] = 'none';
      }

      return res
    })

    const initials = computed<string>(() => [
        // @ts-ignore
        store.state.auth.profile?.firstName && store.state.auth.profile?.firstName.substr(0, 1).toUpperCase(),
        // @ts-ignore
        store.state.auth.profile?.surname && store.state.auth.profile?.surname.substr(0, 1).toUpperCase()
      ].join(''))

    return {
      store,
      initials,
      profileImage,
      style
    }
  }
})
</script>

<style lang="scss">
.in-user-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 100%;
  background: var(--dark2);
  color: #fff;
  text-decoration: none;
  text-align: center;
  /*transform: translateY(-1px);*/
  background-size: cover;

  &:hover {
    background: var(--base1);
    background-size: cover;
  }
}

body.home.top {
  .in-user-avatar {
    background: #fff;
    color: var(--base1);
    background-size: cover;
    &:hover {
      background: var(--darker15);
      color: #fff;
      background-size: cover;
    }
  }
}
</style>

<docs>
```js
<in-user-avatar user="John Doe"/>
```
</docs>