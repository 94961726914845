<template>
  <in-layer class="in-confirm" :width="600" no-close @close="onNo">
    <template #headline>
      {{ title }}
    </template>
    <div class="message">
      <slot></slot>
    </div>
    <template v-slot:buttons>
      <div class="buttons">
        <in-button ref="btnYes" @click="onYes" :disabled="disabled" filled>
          {{ t('yes') }}
        </in-button>
        <in-button ref="btnNo" @click="onNo" :disabled="disabled">
          {{ t('no') }}
        </in-button>
      </div>
    </template>
  </in-layer>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";
import InLayer from "@/components/InLayer.vue";
import {useI18n} from "vue-i18n";
import {onBeforeUnmount, onMounted, ref} from "vue";
import InButton from "@/components/general/InButton.vue";

export default defineComponent({
  components: {InButton, InLayer},

  props: {
    disabled: { type: Boolean, default: false },
    title: { type: String, required: true }
  },

  setup(_, {emit}) {
    const layer = ref<typeof InLayer|null>(null)
    const btnYes = ref<HTMLAnchorElement|null>(null)
    const btnNo = ref<HTMLAnchorElement|null>(null)

    const {t} = useI18n()

    const onYes = () => {
      emit('ok')
    }

    const onNo = () => {
      emit('cancel')
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onNo()
      }
    }

    onMounted(() => {
      btnYes.value?.focus()

      document.body.addEventListener('keyup', onKeyUp)
    })

    onBeforeUnmount(() => document.body.removeEventListener('keyup', onKeyUp))

    return {
      t,
      layer,
      btnYes,
      btnNo,
      onYes,
      onNo
    }
  }
})
</script>
