import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = {
  key: 1,
  class: "body"
}
const _hoisted_7 = {
  key: 2,
  class: "button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_in_ul = _resolveComponent("in-ul")!
  const _component_in_button = _resolveComponent("in-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["icon-text-boxes", _ctx.classes])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config, (c) => {
      return (_openBlock(), _createElementBlock("li", {
        key: c.title,
        class: _normalizeClass({ 'has-button': c.route || c.action })
      }, [
        _createElementVNode("div", _hoisted_1, [
          (c.icon)
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                name: c.icon
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          (c.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: c.image
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "headline",
            textContent: _toDisplayString(c.title)
          }, null, 8, _hoisted_4),
          (c.text)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "body",
                textContent: _toDisplayString(c.text)
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (c.list)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_in_ul, {
                  items: c.list
                }, null, 8, ["items"])
              ]))
            : _createCommentVNode("", true),
          (c.route || c.action)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (c.action)
                  ? (_openBlock(), _createBlock(_component_in_button, {
                      key: 0,
                      onClick: c.action,
                      textContent: _toDisplayString(c.buttonLabel || _ctx.t('find_out_more'))
                    }, null, 8, ["onClick", "textContent"]))
                  : (c.route)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: c.route,
                        class: "in-button",
                        textContent: _toDisplayString(c.buttonLabel || _ctx.t('find_out_more'))
                      }, null, 8, ["to", "textContent"]))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ], 2))
}