import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "in-sidebar-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classNames)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["inner", _ctx.innerClassNames])
    }, [
      (_ctx.hasSidebar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "sidebar")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}