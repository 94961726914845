<template>
    <div class="in-checkbox-list">
        <in-check-box-list-item :item="item" v-for="item of config" :key="item.id" v-model="data[item.id]" :loading="loading"/>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "@vue/runtime-core";
import InCheckBoxListItem from "./InCheckBoxListItem.vue";

export default defineComponent({
  components: { InCheckBoxListItem },
    props: {
        loading: { type: Boolean, default: false },
        config: { type: Object, required: true },
        modelValue: { type: Object, required: true }
    },

    setup(props, { emit }) {
        const data = ref({})

        // @ts-ignore
        for (let item of props.config) {
            if (undefined !== item.id) {
                // @ts-ignore
                data.value[item.id] = {}
            }
        }

        watch(data, () => {
            // emit('update:modelValue', data)
        }, { deep: true, immediate: true })

        watch(data, () => {
            // const res = {}
            const flat = {}
            for (let key of Object.keys(data.value)) {
                if ('undefined' !== key) {
                    // @ts-ignore
                    // res[key] = { value: data.value[key].value }
                    flat[key] = data.value[key].value

                    // @ts-ignore
                    if ('undefined' !== typeof data.value[key].children) {
                        // @ts-ignore
                        // res[key].children = {}

                        // @ts-ignore
                        for (const childKey of Object.keys(data.value[key].children)) {
                            // @ts-ignore
                            // res[key].children[childKey] = { value: data.value[key].children[childKey].value }
                            flat[childKey] = data.value[key].children[childKey].value
                        }
                    }
                }
            }
            emit('update:modelValue', flat)
        }, { deep: true, immediate: true })

        return {
            data
        }
    }
})
</script>

<style lang="scss">
.in-checkbox-list {
  & > .item {
    margin-bottom: 10px;

    label {
      color: var(--base1);
    }

    input[type=checkbox] {
      float: left;
      margin-right: 10px;
    }

    & > label {
      font-weight: bold;
    }

    .item {
      &:first-child {
        margin-top: 5px;
      }
    }
  }

  .item {
    padding: 3px 0;

    label {
      padding-left: 5px;
    }
  }
}
</style>
