/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface ProfileImageDeleteErrorResponse
 */
export interface ProfileImageDeleteErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileImageDeleteErrorResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof ProfileImageDeleteErrorResponse
     */
    code?: ProfileImageDeleteErrorResponseCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileImageDeleteErrorResponseCodeEnum {
    NOIMAGESET = 'NO_IMAGE_SET',
    DELETIONFAILED = 'DELETION_FAILED'
}

