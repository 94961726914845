<template>
  <div class="in-section-headline" :class="{ 'has-sub': !!sub, center }">
    <component :is="hlComponent">
      {{ headline }}
    </component>
    <div class="sub" v-if="sub" v-html="sub"></div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'i-section-headline',

  props: {
    level: {type: Number, default: 2},
    headline: {type: String, required: true,},
    sub: String,
    center: { type: Boolean, default: false }
  },

  setup(props) {
    const hlComponent = `h${props.level}`

    return {
      hlComponent,
    }
  }
})
</script>

<style lang="scss">
.in-section-headline {
  .sub {
    color: var(--base2);
    font-weight: bold;
  }

  &.has-sub {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }

  &.center {
    text-align: center;
  }

  h2 {
    line-height: 60px;
    font-size: 40px;
  }

  @media screen and (max-width: 800px){
    h2 {
      font-size: 24px;
      line-height: 40px;
    }

    .sub {
      font-size: 14px;
    }
  }
}
</style>