<template>
  <in-layer class="in-alert" :width="600">
    <template #headline>
      {{ title }}
    </template>
    <div class="message">
      <slot></slot>
    </div>
    <template v-slot:buttons>
      <div class="buttons">
        <in-button ref="btn" @click="onConfirm">
          {{ t('ok') }}
        </in-button>
      </div>
    </template>
  </in-layer>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";
import InLayer from "@/components/InLayer.vue";
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";
import InButton from "@/components/general/InButton.vue";

export default defineComponent({
  components: {InButton, InLayer},

  props: {
    title: { type: String, required: true }
  },

  setup(_, {emit}) {
    const layer = ref<typeof InLayer|null>(null)
    const btn = ref<HTMLAnchorElement|null>(null)

    const {t} = useI18n()

    const onConfirm = () => {
      emit('confirm')
    }

    onMounted(() => {
      btn.value?.focus()
    })

    return {
      t,
      layer,
      btn,
      onConfirm
    }
  }
})
</script>