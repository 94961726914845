<template>
  <span class="material-icon" v-html="selectedIcon" v-if="selectedIcon"></span>
</template>

<script lang="ts">
import {SVGIconDefinition} from "@/plugins/material-icons/SVGIconDefinition";

interface IconList {
  [name: string]: string
}

let icons: IconList = {}

export default {
  props: {
    name: { type: String }
  },

  setup(props: { [name: string]: string }) {
    const selectedIcon = icons[props.name] || null

    return {
      selectedIcon
    }
  },

  register(...icon: SVGIconDefinition[]) {
    for (let i of icon) {
      icons[i.name] = i.svg
    }
  }
}
</script>
