<template>
  <div :class="classNames">
    <div class="inner" :class="innerClassNames">
      <div class="in-sidebar-menu" v-if="hasSidebar">
        <slot name="sidebar"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, ref} from "vue";
import {computed, defineComponent} from "@vue/runtime-core";

export default defineComponent({
  props: {
    fullHeight: { type: Boolean, default: false },
    noCenter: { type: Boolean, default: false },
    col2: { type: Boolean, default: false },
    colImageSide: { type: String as PropType<'left' | 'right'>, default: 'left' },
    colImagePosition: { type: String as PropType<'start' | 'center' | 'end'>, default: 'center' },
    color: { type: String as PropType<'lightblue'>, default: null },
    hasSidebar: { type: Boolean, default: false }
  },

  setup(props) {
    console.log('SECTION!!!!')
    const classNames = ref<string[]>(['page', 'in-section'])

    if (props.fullHeight) {
      classNames.value.push('full-height')
    }
    if (!props.noCenter) {
      classNames.value.push('center')
    }
    if (props.col2) {
      classNames.value.push('col2')
      classNames.value.push(`image-${props.colImageSide}`)
      classNames.value.push(`image-position-${props.colImagePosition}`)
    }
    if (props.color) {
      classNames.value.push(`color-${props.color}`)
    }

    const innerClassNames = computed(() => {
      const res = []

      if (props.hasSidebar) {
        res.push('in-menu-page')
      }

      return res
    })

    return {
      classNames,
      innerClassNames,
    }
  }
})
</script>

<style lang="scss">
@import '@/scss/mixin/container';

.page {
  padding: 40px 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--text1);
  box-sizing: border-box;

  & > .inner {
    @include container;
    height: 100%;
    width: 100%;
  }

  &.full-height {
    min-height: calc(100vh - 64px);
  }

  &.col2 > .inner {
    display: grid;
    grid-gap: 100px;

    & > .image {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    & > .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr !important;
      grid-gap: 40px;

      & > .image {
        text-align: center;
      }
    }

    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr !important;

      .image {
        display: none;
      }
    }
  }

  &.col2 {
    &.image-left .inner {
      grid-template-columns: .9fr 1.1fr;
    }
    &.image-right .inner {
      grid-template-columns: 1.1fr .9fr;
    }
    &.image-position-start .image {
      justify-content: flex-start !important;
    }
    &.image-position-end .image {
      justify-content: flex-end !important;
    }
  }

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner {
      text-align: left;
    }
  }

  &.color-lightblue {
    background: var(--base5);
  }

  h2 {
    color: var(--dark1);

    span.small {
      display: block;
      font-size: 18px;
      color: var(--base1);
      margin-bottom: -10px;

      @media screen and (max-width: 800px){
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}
</style>