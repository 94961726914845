/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface VerificationErrorResponse
 */
export interface VerificationErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorResponse
     */
    code?: VerificationErrorResponseCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VerificationErrorResponseCodeEnum {
    NOTFOUND = 'NOT_FOUND',
    ALREADYVERIFIED = 'ALREADY_VERIFIED',
    VALIDATIONFAILED = 'VALIDATION_FAILED'
}

