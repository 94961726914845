<template>
    <img src="@/assets/ajax-loader.gif" class="loader" :class="{ small, nostyle, inline }"/>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    small: { type: Boolean, default: false },
    nostyle: { type: Boolean, default: false },
    inline: { type: Boolean, default: false }
  }
})
</script>

<style lang="scss">
img.loader {
  &:not(.nostyle, .inline) {
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 50px;
  }

  &.small {
    width: 30px;
  }
}
</style>

<docs>
```js
<in-loader/>
```
</docs>