import daynames from '@/config/daynames'
import monthnames from '@/config/monthnames'
import {onBeforeUnmount, onMounted, ref, watch} from 'vue'
import i18n from '@/plugins/i18n'
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

export function removeKeyFromObject(obj: object, key: string): object {
    if (!key || 'null' === key) {
        return obj
    }

    const availableKeys = Object.keys(obj).filter(k => k !== key)

    const res = {}
    for (const k of availableKeys) {
        // @ts-ignore
        res[k] = obj[k]
    }

    return res
}

export function translateObject(obj: { [key: string]: string }, prefix: string | null = null) {
    const res: { [key: string]: string } = {}
    for (const key in obj) {
        const k = prefix ? prefix + '.' + obj[key] : obj[key]
        res[key] = i18n.global.t(k)
    }

    return res
}

export function translateArray(arr: any[], prefix: string | null = null) {
    const res = []
    for (let value of arr) {
        if (prefix) {
            value = `${prefix}.${value}`
        }
        res.push(i18n.global.t(value))
    }

    return res
}

export function translateArrayToObject(arr: string[], prefix: string | null = null) {
    const create = () => {
        const res: { [key: string]: string } = {}
        for (const key of arr) {
            let value = key
            if (prefix) {
                value = `${prefix}.${value}`
            }
            res[key] = i18n.global.t(value)
        }

        return res
    }

    const res = ref<object>(create())

    watch(i18n.global.locale, () => {
        res.value = create()
    })

    return res
}

export function createFullDateString(input: string, appendIfValue: string | null = null): string | null {
    if (!input) {
        return null
    }

    const translatedDayNames = translateArray(daynames, 'days')
    const translatedMonthNames = translateArray(monthnames, 'months')

    const [y, m, d] = input.split('-')

    const date = new Date()
    date.setFullYear(parseInt(y))
    date.setMonth(parseInt(m) - 1)
    date.setDate(parseInt(d))
    date.setHours(12)
    date.setMinutes(0)
    date.setSeconds(0)

    return `${translatedDayNames[date.getDay()]}, ${date.getDate()} ${translatedMonthNames[date.getMonth()]}`
        + (appendIfValue ? ', ' + appendIfValue : null)
}

export function buildHash(obj: Object): void {
    const chunks = []
    for (const key in obj) {
        // @ts-ignore
        chunks.push(`${key}=${obj[key]}`)
    }
    location.hash = chunks.join(',')
}

export function readHash(defaultData: object = {}): object {
    const data = {}
    if (location.hash && '#' === location.hash.substr(0, 1)) {
        const hash = location.hash.substr(1)
        const chunks = hash.split(',')

        for (const ch of chunks) {
            const tmp = ch.split('=')
            if (tmp.length === 2 && tmp[0] && tmp[1]) {
                // @ts-ignore
                data[tmp[0]] = tmp[1]
            }
        }
    }

    return {...defaultData, ...data}
}

export function objectHasAllValues(obj: { [key: string]: string }, requiredValues: string[]): boolean {
    return requiredValues
        .filter(key => obj[key])
        .length === requiredValues.length
}

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isDescendant(parent: HTMLElement, child: HTMLElement) {
    let node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export function registerUnloadPrompt(hasChanges: any) {
    watch(hasChanges, () => {
        if (hasChanges.value) {
            sessionStorage.setItem('has-changes', '1')
        } else {
            sessionStorage.removeItem('has-changes')
        }
    })

    const confirmOnUnload = (e: BeforeUnloadEvent) => {
        if (hasChanges.value) {
            e.preventDefault()
            e.returnValue = i18n.global.t('prompt.unsaved_changes')
        }
    }

    onMounted(() => window.addEventListener('beforeunload', confirmOnUnload))

    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', confirmOnUnload)
        sessionStorage.removeItem('has-changes')
    })
}

export function formatDate(value: string) {
    return i18n.global.locale.value === 'de-DE'
        ? value.split('-').reverse().join('.')
        : value
}

const DURATION_IN_SECONDS = {
    epochs: ['year', 'month', 'day', 'hour', 'minute'],
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60
};

function getDuration(seconds: number) {
    let epoch: string | null = null
    let interval: number = 0

    if (seconds < 60) {
        seconds = 60
    }

    for (let i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
        epoch = DURATION_IN_SECONDS.epochs[i];
        // @ts-ignore
        interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
        if (interval >= 1) {
            return {
                interval: interval,
                epoch: epoch
            };
        }
    }

}

// @ts-ignore
export function timeSince(date) {
    const {t} = useI18n()

    // @ts-ignore
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    const duration = getDuration(seconds);

    // @ts-ignore
    return t(`n_${duration.epoch}_ago`, duration.interval)
}

export function setHash(key: string, value: string): void {
    const data = splitHash()
    data[key] = value
    location.hash = joinHash(data)
}

export function splitHash(): {[key: string]: string|number} {
    let hash = location.hash || ''

    if (hash && '#' === hash.substr(0, 1)) {
        hash = hash.substr(1)
    }

    const chunks = hash.split(',')
    const res: {[key: string]: any} = {}
    for (const chunk of chunks) {
        if (chunk.includes('=')) {
            const [key, value] = chunk.split('=')
            res[key] = value
        }
    }

    return res
}

export function joinHash(data: {[key: string]: any}): string {
    const chunks = []
    for (const key in data) {
        chunks.push(`${key}=${data[key]}`)
    }
    return chunks.join(',')
}

export function readFromHash(key: string): any {
    const data = splitHash()
    return data[key] ?? null
}

export function ucfirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function money(number: number) {
    const {t} = useI18n()
    return '€' + number.toFixed(2).replace('.', t('decimalPoint'))
}