<template>
  <ul class="in-ol">
    <li v-for="(item, i) of items" :key="item">
      <div class="num">{{ i + 1 }}</div>
      <div class="text">{{ item }}</div>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
  props: {
    items: {
      type: Array as () => String[]
    },
  }
})
</script>

<style lang="scss">
@import '@/scss/vars';

.in-ol {
  list-style-type: none;
  margin: 32px 0;
  padding: 0;

  li {
    display: flex;

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;
    }

    .num {
      flex-grow: 0;
      flex-shrink: 0;
      color: var(--base1);
      font-size: 40px;
      width: 60px;
      text-align: left;
    }

    @media screen and (max-width: 1200px) {
      font-size: 14px;

      & > * {
        margin: 10px 0;
      }

      .num {
        font-size: 30px;
        width: 50px;
      }
    }
  }
}
</style>

<docs>
```js
<in-ol :items="[
    'Item 1',
    'Item 2'
]"/>
```
</docs>