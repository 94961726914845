<template>
  <in-header-dropdown id="notifications" class="in-notification-area" :class="{ notify }" :empty="unreadNotificationCount < 1" :width="400" :max-height="maxHeight"
                      @open="onOpen" @close="onClose">
    <template #trigger>
      <span class="icon">
        <font-awesome-icon icon="bell"/>
        <span class="unread-count" v-if="unreadNotificationCount" v-text="unreadNotificationCount"></span>
      </span>
    </template>
    <template #dropdown>
      <div class="header">
        {{t('notification', 2)}}
        <router-link class="settings" :to="{ name: 'notifications' }">
          <svg-icon name="settings_outline"/>
        </router-link>
      </div>
      <ul v-if="notifications.length > 0" ref="elList">
        <li class="notification" :class="{ seen: !! n.seen }" v-for="n of notifications" :key="n.id">
          <div class="content" v-html="n.content"></div>
          <div class="timestamp">{{timeSince(n.created)}}</div>
        </li>
      </ul>
      <ul v-else>
        <li class="notification empty">
          <material-icon name="notifications-active-outlined" scale="5"/>
          <div v-html="t('no_notifications_message')"></div>
        </li>
      </ul>
    </template>
  </in-header-dropdown>
</template>

<script lang="ts">
import InHeaderDropdown from "./InHeaderDropdown.vue";
import {computed, defineComponent, ref, watch} from "vue";
import {ProfileApi} from "@/inpriton-client";
import {useI18n} from "vue-i18n";
import {NotificationModel, SetNotificationsReadRequest} from "@/inpriton-client/models";
import emitter from "@/plugins/emitter";
// @ts-ignore
import worker from '@/plugins/shared-worker'
import {useNotifyClient} from "@/plugins/ws-notify";
import {nextTick} from "@vue/runtime-core";
import {timeSince} from "@/functions";

export default defineComponent({
  components: {InHeaderDropdown},

  setup() {
    const {t, locale} = useI18n()

    const notify = ref<boolean>(false)
    const isChanged = ref<boolean>(false)
    const askForPermission = ref<boolean>(false)
    const maxHeight = ref<number>(0)

    const notifications = ref<NotificationModel[]>([])

    const elList = ref<HTMLUListElement|null>(null)

    const unreadNotificationCount = computed(() => notifications.value?.filter(n => !n.seen).length)

    const onOpen = () => {
      if ( ! ['granted', 'denied'].includes(Notification.permission)) {
        Notification.requestPermission()
      }

      if (notifications.value.length > 0) {
        const api = new ProfileApi()

        const request: SetNotificationsReadRequest = { ids: notifications.value.map(n => n.id) }

        api.setNotificationsRead(request)
          .then(() => {
            isChanged.value = true
            emitter.emit('notifications-changed')
          })
      }

      nextTick(calculateHeight)
    }

    const onClose = () => {
      if (isChanged.value) {
        isChanged.value = false
        loadNotifications()
      }
    }

    const calculateHeight = () => {
      if (elList.value) {
        if (elList.value.childNodes.length >= 6) {
          const itemsToShow = Array.from(elList.value.childNodes).slice(0, 6)

          let height = 0
          for (let item of itemsToShow) {
            // @ts-ignore
            if ('undefined' === typeof item.getBoundingClientRect) {
              continue
            }
            console.log(item)
            // @ts-ignore
            height += Math.ceil(item.getBoundingClientRect().height)
          }

          maxHeight.value = height + 56
          console.log('HEIGHT', maxHeight.value)
        } else {
          maxHeight.value = 0
        }
      }
    }

    const loadNotifications = () => {
      const api = new ProfileApi()

      api.getNotifications()
        .then(({data}) => {
          // @ts-ignore
          notifications.value = data.sort((a, b) => a.id > b.id ? -1 : 1)

          console.log('loaded')
          nextTick(calculateHeight)
        })
    }

    loadNotifications()

    watch(locale, loadNotifications)

    if (worker.worker) {
      worker.worker.port.addEventListener('message', (/*data: {title: string, message: string}*/) => {
        loadNotifications()

        notify.value = true
        setTimeout(() => notify.value = false, 1000)
      })
    } else {
      const client = useNotifyClient()

      client.register('notify-area', () => { return null; }, () => {
        loadNotifications()

        notify.value = true
        setTimeout(() => notify.value = false, 1000)
      })
    }

    return {
      t,
      notify,
      notifications,
      askForPermission,
      unreadNotificationCount,
      elList,
      maxHeight,
      timeSince,
      onOpen,
      onClose
    }
  }
})
</script>

<style lang="scss">
@keyframes notify {
  0% { transform: none }
  25% { transform: rotate(45deg) }
  75% { transform: rotate(-45deg) }
  100% { transform: none }
}

.in-notification-area {
  .dropdown {
    padding: 0 !important;
  }

  a.settings {
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 0;
    transform: translateY(12px);
    height: 24px;

    svg {
      height: 24px;
      width: 24px;
      fill: var(--base4);
    }
  }

  .icon {
    position: relative;

    .unread-count {
      position: absolute;
      color: #fff;
      font-weight: bold;
      line-height: 19px;
      font-size: 12px;
      text-align: center;
      box-shadow: none;
      border: none;
      left: 11px;
      top: -7px;
      padding: 0;
      margin: 0;
      background: #f00;
      width: 19px;
      height: 19px;
      border-radius: 100%;
    }
  }

  &.notify .icon svg {
    animation: notify .4s infinite;
  }

  .dropdown:not(.empty) .list {
    max-height: 370px;
  }

  li.notification {
    color: #000;
    text-align: left;
    padding: 18px 30px;
    line-height: 25px;
    cursor: pointer;

    &:hover {
      background: var(--base5);
    }

    &.empty {
      text-align: center;
      padding: 75px 30px 131px;
      margin-bottom: 18px;
      cursor: default;
      max-height: 370px;

      svg {
        color: var(--base4);
        margin-bottom: 20px;
      }

      .title {
        margin-bottom: 20px !important;
      }

      &:hover {
        background: #fff;
      }

      .title {
        font-size: 18px;
        margin-bottom: 3px;
      }
    }

    .timestamp {
      color: var(--base2);
      font-size: 14px;
    }

    &.seen {
      .timestamp {
        color: #000;
        opacity: .5;
      }
    }

    .content {
      font-size: 16px;
    }
  }
}
</style>
