import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import {faFileAlt} from '@fortawesome/free-solid-svg-icons/faFileAlt'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope'
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown'
import {faArrowDown} from '@fortawesome/free-solid-svg-icons/faArrowDown'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons/faArrowUp'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner'
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck'
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser'
import {faStar} from '@fortawesome/free-solid-svg-icons/faStar'
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock'
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import {faGlobe} from '@fortawesome/free-solid-svg-icons/faGlobe'
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight'

import {faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons/faStar'
import {faUserCircle} from '@fortawesome/free-regular-svg-icons/faUserCircle'
import {faFacebookF, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";

library.add(faGlobe, faMapMarkerAlt, faCalendarAlt, faClock, faBars, faQuestionCircle, faStar, faStarRegular, faUser, faUserCheck, faTimes,
    faSpinner, faPhone, faArrowUp, faArrowLeft, faArrowRight, faArrowDown, faChevronDown, faChevronUp, faEye, faEyeSlash,
    faEnvelope, faFileAlt, faUserCircle, faSignOutAlt, faCheckCircle, faBell, faInstagram, faTwitter, faFacebookF, faChevronLeft, faChevronRight)

export default FontAwesomeIcon
