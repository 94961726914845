<template>
  <table>
  </table>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: {
        config: { type: Array, required: true },
        callback: { required: true, validator: val => 'function' === typeof val }
    }
})
</script>

<style>

</style>