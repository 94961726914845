import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_button = _resolveComponent("in-button")!
  const _component_in_layer = _resolveComponent("in-layer")!

  return (_openBlock(), _createBlock(_component_in_layer, {
    class: "in-confirm",
    width: 600,
    "no-close": "",
    onClose: _ctx.onNo
  }, {
    headline: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_in_button, {
          ref: "btnYes",
          onClick: _ctx.onYes,
          disabled: _ctx.disabled,
          filled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('yes')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_in_button, {
          ref: "btnNo",
          onClick: _ctx.onNo,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('no')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["onClose"]))
}