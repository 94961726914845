import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "in-ol" }
const _hoisted_2 = { class: "num" }
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("li", { key: item }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(i + 1), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item), 1)
      ]))
    }), 128))
  ]))
}