<template>
  <in-input class="in-password-field" :type="type" v-bind="$attrs" v-model="value" ref="field">
    <a href="javascript:void(0);" class="in-addon" @click="toggle" tabindex="-1" onfocus="this.blur()">
      <material-icon :name="'text' === type ? 'visibility-off-outlined' : 'visibility-outlined'" :scale="1.1"/>
    </a>
  </in-input>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import {ref, watch} from "vue";
import InInput from "@/components/general/InInput.vue";

export default defineComponent({
  components: {InInput},
  props: {
    modelValue: null
  },

  setup(props, { emit }) {
    const field = ref<typeof InInput|null>(null)
    const type = ref<string>('password')
    const value = ref<string>(props.modelValue)

    const toggle = () => {
      type.value = 'password' !== type.value ? 'password' : 'text'
      field.value?.focus()
    }

    watch(value, () => {
      emit('input', value.value)
    })

    return {
      field,
      type,
      value,
      toggle
    }
  }
})
</script>

<style lang="scss">
.in-password-field {
  position: relative;
}

a.in-addon {
  position: absolute;
  top: 18px;
  right: 12px;

  svg {
    color: var(--dark3);
    width: 18px;
  }
}
</style>

<docs>
```js
<in-password-field label="Password"/>
```
</docs>