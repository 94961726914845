<template>
  <div>
    <div class="inner" style="display: block">
<!--
      <chat-window-handler></chat-window-handler>
      <router-link :to="{ name: 'voip_test'}">VoIP</router-link>
-->

      <in-page-header
          :background-image="require('../assets/img/Background_Startseite-1920x1000.png')"
          :headline="t('home.title')"
          :text="t('home.description')">
        <div class="buttons page-header-buttons">
          <in-button @click="toggleSignUp" large filled>
            Kostenlos testen
          </in-button>
          <in-button :route="{ name: 'prices' }" large>
            Unsere Preise
          </in-button>
        </div>
      </in-page-header>

      <div class="content-wrapper" v-show="bannerValid && submitted">
        <div class="sidebar">
          <in-check-box-list :config="searchoptions" v-model="sidebarData" :loading="loading"/>
        </div>
        <div class="content">
          <div class="headline">
            {{ languages[submittedData.lang_from] }} &#x2192; {{ languages[submittedData.lang_to] }}<br>
            {{displayDate}}
          </div>

          <div class="center" v-if="loading" style="margin-top: 30px;">
            <in-loader inline/>
          </div>

          <div v-else>
            <in-data-table :config="[]" :callback="dataTableCallback"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <in-section full-width full-height>
    <in-section-headline
        :headline="t('home.section1.headline')"
        :sub="t('home.section1.subHeadline')"
    />

    <icon-text-boxes :config="[
            {
              image: require('../assets/icons/Icon_Flexibility.svg'),
              title: 'Maximale Flexibilität, Kosten- und Zeitersparnis',
              text: 'Finden Sie passende Dolmetscher:innen schnell und rund um die Uhr. Treten Sie für Fragen oder Abstimmungen mit nur wenigen Klicks über unser integriertes Messaging Tool oder VoIP-Telefonie auf der Platform direkt mit ihnen in Kontakt.',
              route: { to: 'why' }
            },
            {
              image: require('../assets/icons/Icon_Video-interpreting.svg'),
              title: 'Video Call mit allen benötigen Zusatzfeatures',
              text: 'Durch unsere Onlinelösung mit Video- und Audiodolmetschen sind Sie weder zeitlich noch örtlich gebunden. Zusätzlich finden Sie alle wichtigen Anwendungen für eine Dolmetschsitzung, wie z.B. Desktop-Sharing, digitale Signaturen, Google-Maps-Einbindungen, automatische Abrechnungssysteme uvm. auf unserer Plattform zentral vereint.',
              route: { to: 'why' }
            },
            {
              image: require('../assets/icons/Icon_B2B-customers.svg'),
              title: 'Kundenspezifische Lösungen für Geschäftskund:innen',
              text: 'Für Unternehmen, Behörden und Institutionen bieten wir auch individualle Lösungen unserer Plattform an. Diese beinhalten unter anderem eine an die eigene Unternehmenslogik angepasste Umgebung sowie kundenspezifische Benutzeroberflächen für die benötigten Dolmetschdienste.',
              route: { to: 'why' }
            },
        ]"/>
  </in-section>

  <in-section full-width :color="'lightblue'">
    <in-section-headline
      center
      :headline="t('home.section2.headline')"
      :sub="t('home.section2.subHeadline')"
    />

    <icon-text-boxes mobile-vertical :config="[
        {
          image: require('../assets/icons/Icon_Monitor with browser.svg'),
          title: 'Hauptmerkmale',
          list: [
            'Kundenportal',
            'sichere Anmeldung',
            'hochwertiges Video',
            'kristallklarer Ton',
            'Cross-Platform Kompatibilität',
            'benutzerfreundliche Oberfläche',
          ]
        },
        {
          image: require('../assets/icons/Icon_Discover.svg'),
          title: 'Wichtige integrierete Anwendungen',
          list: [
              'Video Calling',
              'Chat und Messaging',
              'Desktop Sharing',
              'Dokumentenfreigabe',
              'digitalte Signatur',
          ]
        },
        {
          image: require('../assets/icons/Icon_Security.svg'),
          title: 'Sicherheit und Datenschutz',
          list: [
              'Ende-zu-Ende-Verschlüsselung',
              'WebRTC',
              'SSL-Verschlüsselung',
              'Zugriffskontrollen',
              'EU-Datenschutzkonform',
              'österreichische Server-Infrastruktur',
          ]
        },
    ]" no-shadow icon-left/>
  </in-section>

  <in-section full-width full-height col2>
    <div class="image">
      <img src="@/assets/img/stock1.jpg"/>
    </div>
    <div class="center">
      <div class="inner">
        <in-section-headline
          :headline="t('home.section3.headline')"
          :sub="t('home.section3.subHeadline')"
        />

        <in-ol :items="[
            'Auf Inpriton anmelden oder innerhalb weniger Minuten registrieren',
            'Als Host Videodolmetschsitzung planen und Termineinladung an Ihren/Ihre Dolmetscher/in über Email verschicken',
            'Videodolmetsch-ID (als Host und Teilnehmer/in) per Email erhalten',
            'Mit Videodolmetsch-ID direkt aus Email oder von unserer Webseite aus Videodolmetschsitzung starten bzw. an einer Videodolmetschsitzung teilnehmen',
            'Sie werden automatisch zu einem neuen Fenster weitergeleitet. Aus diesem Fenster einen Video Call starten bzw. an einem Video Call teilnehmen und alle Features inklusive integrierte Anwendungen in Ihrem Video Call in Echtzeit benutzen',
            'Video Call beenden bzw. verlassen',
            'Als Host die Videodolmetschsitzung beenden',
        ]"/>

        <div style="margin-bottom: 40px">
          Die Verbindung Ihrer Videodolmetschsitzung bleibt immer aufrecht, auch wenn Teilnehmer:innen den Call aus Versehen verlassen bzw. das Video Call Fenster schließen oder die Internetverbindung instabil ist. Sie können jederzeit wieder an Ihrer Dolmetschsitzung teilnehmen bis sie durch den Host beendet ist. Hochgeladene Dokumente bleiben im Video Call Fenster solange erhalten, bis die Sitzung ebenfalls beendet wird.
        </div>

        <in-button
            v-text="t('test_for_free')"
            :route="{ name: 'why' }"
            no-upper-case
        />
      </div>
    </div>
  </in-section>
</template>

<script>
import {computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from '@vue/runtime-core'
import {buildHash, createFullDateString, readHash, translateArrayToObject} from '@/functions'
import InLoader from '@/components/general/InLoader.vue'
import InCheckBoxList from '@/components/general/InCheckBoxList.vue'
import InDataTable from '@/components/general/InDataTable.vue'
import searchoptions from '@/config/searchoptions'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import InOl from "@/components/general/InOl.vue";
import IconTextBoxes from "@/components/general/IconTextBoxes.vue";
import InSectionHeadline from '../components/InSectionHeadline.vue'
import InSection from "@/components/InSection.vue";
import InButton from "@/components/general/InButton.vue";
import InPageHeader from "@/components/InPageHeader.vue";
//import ChatWindowHandler from "@/components/ChatWindowHandler.vue";

const iconFlexibility = null // <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56"><g id="Icon_Flexibility_01" transform="translate(0 -329)"><rect id="Rechteck_1684" data-name="Rechteck 1684" width="56" height="56" transform="translate(0 329)" fill="#fff"/><path id="streetsign" d="M26.25,55.125V38.5c0-.483-.233-1.75-.716-1.75a1.026,1.026,0,0,0-1.034.875v17.5A1.024,1.024,0,0,0,25.534,56C26.019,56,26.25,55.608,26.25,55.125Zm.159-52.391V.875a.875.875,0,0,0-1.75,0V2.734a.875.875,0,1,0,1.75,0ZM6.808,6.074l-6.242,4.9A1.749,1.749,0,0,0,0,12.25,1.785,1.785,0,0,0,.62,13.576l6.134,4.8a3.679,3.679,0,0,0,2.228.87h24.2A1.816,1.816,0,0,0,35,17.442V7.058A1.816,1.816,0,0,0,33.18,5.25H8.981a3.63,3.63,0,0,0-2.174.824ZM33.25,17.442a.069.069,0,0,1-.07.058H8.981a2,2,0,0,1-1.094-.451L1.7,12.3,7.94,7.4A1.979,1.979,0,0,1,8.981,7l24.269.058V17.442ZM44,21.87A3.679,3.679,0,0,0,41.769,21H17.57a1.816,1.816,0,0,0-1.82,1.808V33.194A1.816,1.816,0,0,0,17.57,35h24.2a3.64,3.64,0,0,0,2.175-.824l6.241-4.9A1.749,1.749,0,0,0,50.75,28a1.785,1.785,0,0,0-.62-1.326L44,21.87Zm-26.5.88H41.769a2,2,0,0,1,1.094.451l6.188,4.748-6.241,4.9a1.979,1.979,0,0,1-1.041.4H17.5V22.75Z" transform="translate(0 329)" fill="#1976d2"/></g></svg>

export default {
  name: 'Home',

  components: {
    InPageHeader,
    InButton,
    InSection,
    IconTextBoxes,
      InOl,
//    ChatWindowHandler,
    InLoader,
    InCheckBoxList,
    InDataTable,
    InSectionHeadline
  },

  setup() {
    const formatDate = date => {
        return [
            [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).substr(-2),
                ('0' + date.getDate()).substr(-2),
            ].join('-'),
            [
                ('0' + date.getHours()).substr(-2),
                ('0' + date.getMinutes()).substr(-2),
            ].join(':')
        ].join('T')
    }

    const data = reactive({
      loading: false,
      submitted: false,
      submittedData: {},
      mainFilters: {
        languages: 'en',
        type: null,
        start: formatDate(new Date()),
        end: formatDate(new Date()),
      },
      sidebarData: {},
      bannerValid: false,
      isMounted: false
    })

    const store = useStore()

    const { t } = useI18n()

    const translatedLanguages = ref(translateArrayToObject(store.state.languages.translations.map(l => l.code), 'languages'))

    data.mainFilters = readHash(data.mainFilters)

    const displayDate = computed(() => createFullDateString(
      data.submittedData.date,
      `${t('from').toLowerCase()} ${data.submittedData.time_from} ${t('until').toLowerCase()} ${data.submittedData.time_to}`
    ))

    const filters = computed(() => ({ ...data.submittedData, ...data.sidebarData}))

    watch(filters, () => {
      if (data.isMounted && data.submitted) {
        onSubmit()
      }
    }, {deep: true})

    let lastSubmit = null

    const onSearchBannerSubmit = () => {
      data.submittedData = { ...data.mainFilters }
      nextTick(() => {
        onSubmit()
      })
    }

    const onSubmit = () => {
      if (lastSubmit) {
        const secondsSince = Date.now() - lastSubmit

        if (secondsSince < 50) {
          return;
        }
      }

      lastSubmit = Date.now()

      if ( ! data.submitted) {
        data.submitted = true
      }

      buildHash(data.submittedData)

      data.loading = true

      setTimeout(() => {
        data.loading = false
      }, 500)
    }

    onMounted(() => {
      data.isMounted = true
      if (data.bannerValid) {
        nextTick(() => {
          onSearchBannerSubmit()
        })
      }
    })

    const onBannerValid = () => { data.bannerValid = true }
    const onBannerInvalid = () => { data.bannerValid = false; data.submitted = false }

    const toggleSignUp = () => {
      store.commit('SHOW_SIGN_UP', ! store.state.signUpVisible)
    }

    onBeforeUnmount(() => {
      data.mainFilters = {}
      data.submittedData = {}
      data.bannerValid = false
      data.submitted = false
      data.isMounted = false

      document.body.classList.remove('no-search')
    })

    return {
      ...toRefs(data),
      t,
      onBannerValid,
      onBannerInvalid,
      filters,
      languages: translatedLanguages,
      displayDate,
      onSubmit,
      onSearchBannerSubmit,
      searchoptions,
      iconFlexibility,
      toggleSignUp,
      dataTableCallback: () => {
        return {
          data: []
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 34px;

  .in-button {
    margin-top: 16px;
  }

  .in-button:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 460px) {
}
</style>
