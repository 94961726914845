<template>
  <div class="item" :id="id">
    <hr v-if="'separator' === item.type">

    <input :type="item.type" :id="item.id" :disabled="loading" v-model="data.value" @change="onChange"
           v-else-if="item.type === 'checkbox'"/>

    <label :for="item.id" v-if="'undefined' !== item.type">
      <in-star-rating :value="item.stars" v-if="item.stars"/>
      {{ item.title }}
      <a href="javascript:void(0);" v-if="'undefined' !== item.help && item.help" :title="item.help">
        <font-awesome-icon icon="question-circle" class="help-icon"/>
      </a>
    </label>

    <div class="children" v-if="'undefined' !== item.children && item.children" :ref="`child-${item.id}`">
      <in-check-box-list-item :item="item" v-for="item of item.children" :key="item.id" @init="id => onChildInit(item.id, id)" v-model="data.children[item.id]"
                              @change="onChildChange" :loading="loading"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "@vue/runtime-core";
import InStarRating from "./InStarRating.vue";
import {uuidv4} from "@/functions";
import {onMounted} from "vue";

export default defineComponent({
  components: {InStarRating},

  props: {
    loading: {type: Boolean, default: false},
    item: {type: Object, required: true},
    modelValue: {}
  },

  setup(props, {emit}) {
    const id = uuidv4()
    // @ts-ignore
    const data = ref({...{value: false}, ...props.modelValue})

    if (undefined !== props.item.children && props.item.children) {
      data.value.children = {}
      for (let child of props.item.children) {
        if (undefined !== child.id) {
          data.value.children[child.id] = {}
        }
      }
    }

    // const elem = computed(() => document.getElementById(id))

    const onChildInit = (itemId: string, id: string) => {
      data.value.children[itemId].domid = id
    }

    const onChildChange = (e: Event) => {
      if (undefined === props.item.group || 'single' !== props.item.group) {
        return
      }
      // @ts-ignore
      if (undefined !== e && undefined !== e.target && e.target?.checked) {
        // @ts-ignore
        const childId = e.target?.parentNode?.id
        // const childChecked = e.target.checked
        for (let key in data.value.children) {
          const child = data.value.children[key]
          if (child.domid !== childId) {
            data.value.children[key].value = false
          }
        }


        // elem.value.querySelectorAll(`.item:not([id="${childId}"] input[type=checkbox]`).forEach(chk => chk.checked = false)
      }
    }

    watch(data, () => {
      emit('update:modelValue', data)
    }, {deep: true, immediate: true})

    const onChange = () => {
      if ((undefined === data.value.group || 'single' !== data.value.group) && undefined !== data.value.children) {
        for (let key in data.value.children) {
          data.value.children[key].value = data.value.value
        }
      }

      emit('change', data.value.value)
    }

    onMounted(() => {
      emit('init', id)
    })

    return {
      id,
      data,
      onChange,
      onChildChange,
      onChildInit
    }
  }
})
</script>

<style>
.help-icon {
  cursor: help;
}
</style>
