/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface UpdateFailedResponse
 */
export interface UpdateFailedResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedResponse
     */
    code?: UpdateFailedResponseCodeEnum;
    /**
     * 
     * @type {Array&lt;UpdateFailedResponseDetails&gt;}
     * @memberof UpdateFailedResponse
     */
    details?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedResponseCodeEnum {
    MISSINGDATA = 'MISSING_DATA',
    VALIDATIONFAILED = 'VALIDATION_FAILED'
}

