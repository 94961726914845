import { createApp } from 'vue'
import App from './App.vue'
import router from './plugins/router'
import i18n from './plugins/i18n'
import store from './plugins/store'
import PortalVue from 'portal-vue'

import FontAwesomeIcon from './plugins/icons'
import SvgIcon from './plugins/material-icons'
import VueSimpleSVG from 'vue3-simple-svg'

import InAlert from "@/components/InAlert.vue";
import InConfirm from "@/components/InConfirm.vue";
import InLayer from "@/components/InLayer.vue";

import '@/scss/app.scss'

import globalAxios from "axios";

/*
// @ts-ignore
import runtime from 'serviceworker-webpack-plugin/lib/runtime'
// @ts-ignore
import registerEvents from 'serviceworker-webpack-plugin/lib/browser/registerEvents'
*/

globalAxios.defaults.headers['content-type'] = 'application/json'

/*
if ('serviceWorker' in navigator) {
    runtime.register()
}
*/

createApp(App)
    .use(router)
    .use(i18n)
    .use(VueSimpleSVG)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('svg-icon', SvgIcon)
    .component('in-layer', InLayer)
    .component('in-alert', InAlert)
    .component('in-confirm', InConfirm)
    .use(store)
    .use(PortalVue)
    .mount('#app')

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/service-worker.ts')
//         .then((reg) => {
//             console.log('service worker registered')
//
//             navigator.serviceWorker.ready.then(reg => {
//                 reg.active?.postMessage("Hi service worker");
//             });
//
//             navigator.serviceWorker.addEventListener('message', message => {
//                 console.log("Got message from service worker", message);
//             });
//
//             const channel = new BroadcastChannel('sw-messages');
//
//             channel.addEventListener('message', event => {
//                 console.log('Received from channel', event.data);
//             });
//         })
//         .catch(error => {
//             console.log('service worker registration failed', error)
//         })
// } else {
//     console.log('service worker not available')
// }
