<template>
  <header id="main-header">
    <div class="inner">
      <div class="left">
        <router-link :to="{ name: 'home' }" class="logo" ref="logo">
          <app-logo/>
        </router-link>

        <ul class="main-nav" ref="menu" :class="{ invisible: ! store.state.initialized }">
          <li v-for="route in menuItems" :key="route.to">
            <router-link :to="{ name: route.to }" v-text="route.name" v-if=" ! route.action"></router-link>
            <a href="javascript:void(0);" @click="route.action" v-text="route.name" v-else></a>
          </li>
        </ul>
      </div>

      <ul class="sub-nav" ref="subNav">
        <li v-if="store.state.initialized" id="language-switch">
          <in-language-switcher ref="langswitch" @open="onLangSwitchOpen" />
        </li>
        <li v-if="store.state.auth.profile">
          <in-notification-area/>
        </li>
        <li class="no-right auth-button">
          <in-header-dropdown id="responsive-nav">
            <template #trigger>
              <a href="javascript:void(0);" class="has-border">
                {{ t('video_interpreting') }}
              </a>
            </template>
            <template #dropdown>
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    Videodolmetschen planen
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    Videodolmetschen starten
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    An Videodolmetschen teilnehmen
                  </a>
                </li>
              </ul>
            </template>
          </in-header-dropdown>
        </li>
        <li class="auth-button">
          <a href="javascript:void(0);" @click="toggleSignIn">
            {{ t('sign_in') }}
          </a>
        </li>
        <li v-if="store.state.initialized && ! username " class="no-right" id="responsive-nav-li">
          <in-header-dropdown id="menu-burger">
            <template #trigger>
              <span class="menu-burger">
                <font-awesome-icon icon="bars"/>
              </span>
            </template>
            <template #dropdown>
              <ul>
                <li v-if=" ! username" class="padding-zero add-border">
                  <a href="javascript:void(0);" @click="toggleSignUp">
                    {{ t('sign_up') }}
                  </a>
                </li>
                <li v-if=" ! username" class="padding-zero">
                  <a href="javascript:void(0);" @click="toggleSignIn">
                    {{ t('sign_in') }}
                  </a>
                </li>
                <li v-for="route in menuItems" :key="route.to" class="padding-zero">
                  <router-link :to="{ name: route.to }" v-text="route.name" v-if=" ! route.action"></router-link>
                  <a href="javascript:void(0);" @click="route.action" v-text="route.name" v-else></a>
                </li>
              </ul>
            </template>
          </in-header-dropdown>
        </li>
        <li v-if="username" class="no-right">
          <in-header-dropdown id="user-nav">
            <template #trigger>
              <in-user-avatar :user="store.state.auth.profile"/>
            </template>
            <template #dropdown>
              <ul>
                <li class="padding-zero">
                  <router-link :to="{ name: 'profile' }">
                    {{ t('profile') }}
                  </router-link>
                </li>
                <li v-if="profile?.isReady" class="padding-zero">
                  <router-link :to="{ name: 'orders' }">
                    {{ t('order', 2) }}
                  </router-link>
                </li>
                <li class="separator"></li>
                <li class="padding-zero">
                  <a href="javascript:void(0);" @click="promptLogout = true" class="font-after-separator">
                    {{ t('sign_out') }}
                  </a>
                </li>
              </ul>
            </template>
          </in-header-dropdown>
        </li>
      </ul>
    </div>
  </header>

  <in-confirm v-if="promptLogout" :title="t('sign_out')" @ok="logout" @cancel="promptLogout = false" :disabled="loggingOut">
    {{ t('sign_out_confirm') }}
  </in-confirm>

  <in-alert v-if="store.state.alert" @confirm="store.commit('ALERT', null)">
    {{ store.state.alert }}
  </in-alert>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'

import AppLogo from './AppLogo.vue'
import InLanguageSwitcher from './general/InLanguageSwitcher.vue';

import router from "@/plugins/router";
import InConfirm from "@/components/InConfirm.vue";
import emitter, {EVENT_SIGNED_OUT} from "@/plugins/emitter";
import InAlert from "@/components/InAlert.vue";
import InUserAvatar from "@/components/general/InUserAvatar.vue";
import {isDescendant} from "@/functions";
import InHeaderDropdown from "@/components/header/InHeaderDropdown.vue";
import {UserModelExtended} from "@/inpriton-client/models";
import InNotificationArea from "@/components/header/InNotificationArea.vue";
import {AuthAction} from "@/plugins/store/auth/AuthStore";

export default defineComponent({
  components: {
    InNotificationArea, InHeaderDropdown, InUserAvatar, InAlert, InConfirm, AppLogo, InLanguageSwitcher},

  setup() {
    const store = useStore()

    const logo = ref(null)
    const menu = ref(null)
    const subNav = ref(null)

    const langswitch = ref(null)

    const showResponsiveMenu = ref(false)
    const showUserMenu = ref(false)
    const promptLogout = ref(false)

    const loggingOut = computed<boolean>(() => store.state.auth.signOutLoading)

    const profile = computed<UserModelExtended|null>(() => store.state.auth.profile)

    const {t, locale} = useI18n({useScope: 'global'})

    const username = computed(() => store.state.auth.profile?.fullName)

    const toggleSignIn = () => {
      store.commit('SHOW_SIGN_IN', ! store.state.signInVisible)
    }

    const toggleSignUp = () => {
      store.commit('SHOW_SIGN_UP', ! store.state.signUpVisible)
    }

    const onClick = (e: MouseEvent) => {
      // @ts-ignore
      if (showResponsiveMenu.value && e.target && ! isDescendant(document.querySelector('header'), e.target)) {
        showResponsiveMenu.value = false
      }
      // @ts-ignore
      if (showUserMenu.value && e.target && ! isDescendant(document.querySelector('header'), e.target)) {
        showUserMenu.value = false
      }
    }

    const onLoggedOut = () => {
      promptLogout.value = false
      router.push({ name: 'home' })
    }

    onMounted(() => {
      document.body.addEventListener('click', onClick)
      emitter.on(EVENT_SIGNED_OUT, onLoggedOut)
    })

    watch(showResponsiveMenu, show => {
      if (show) {
        if (showUserMenu.value) showUserMenu.value = false
        // @ts-ignore
        if (langswitch.value) langswitch.value?.close()
      }
    })

    watch(showUserMenu, show => {
      if (show) {
        if (showResponsiveMenu.value) showResponsiveMenu.value = false
        // @ts-ignore
        if (langswitch.value) langswitch.value?.close()
      }
    })

    watch(router.currentRoute, () => {
      showResponsiveMenu.value = false
    })

    const onLangSwitchOpen = () => {
      showResponsiveMenu.value = false
      showUserMenu.value = false
    }

    const logout = async () => {
      await store.dispatch(`auth/${AuthAction.SIGN_OUT}`)
    }

    const createMenuItems = () => {
      return [
        {to: 'why', name: t('why_inpriton')},
        {to: 'services', name: t('services')},
        {to: 'prices', name: t('prices')},
        {to: 'about', name: t('about_us')},
        {to: 'contact', name: t('contact')},
      ];
    }

    const menuItems = ref(createMenuItems())

    watch(locale, () => {
      menuItems.value = createMenuItems()
    })

    watch(username, () => {
      menuItems.value = createMenuItems()
    })

    return {
      logo,
      menu,
      subNav,
      showResponsiveMenu,
      showUserMenu,
      menuItems,
      langswitch,
      store,
      profile,
      username,
      onLangSwitchOpen,
      toggleSignIn,
      toggleSignUp,
      promptLogout,
      loggingOut,
      logout,
      t
    }
  },
})
</script>

<style lang="scss">
@import '@/scss/mixin/container';

header#main-header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  text-align: center;
  height: 64px;
  line-height: 64px;
  background: #fff;
  color: var(--base1);
  box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
  display: flex;
  justify-content: space-between;

  .inner {
    @include container;
    display: flex;
    justify-content: space-between;

    & > .left {
      display: flex;
    }
  }

  svg.svg-inline--fa {
    color: var(--dark2);
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    width: 130px;
  }

  li {
    display: inline-block;
    padding: 0 10px;
    line-height: 100%;
    vertical-align: middle;

    &:last-child {
      padding-right: 0;
    }

    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      color: var(--dark2);
      height: 24px;
      line-height: 24px;

      &:hover {
        color: var(--base1);
      }
    }
  }

  .main-nav {
    z-index: 20;
    top: 0;
    left: 174px;
    list-style-type: none;
    margin: 0 20px;
    padding: 0;
  }

  .sub-nav {
    z-index: 20;
    list-style-type: none;
    margin: 0 0 0 20px;
    padding: 0;

    li {
      &.no-right, &.no-right a.trigger {
        padding-right: 0;
      }

      .padding-zero {
        padding: 0;
      }

      .has-border {
        border: 1px solid var(--base1);
        color: var(--base1);
        padding: 4px 20px;
        border-radius: 20px;

        &:hover {
          color: #fff;
          background: var(--base1);
        }
      }

      .search-button {
        display: inline-block;
        background: #fff;
        border: 1px solid var(--dark4);
        color: var(--dark2);
        padding-left: 28px;
        padding-right: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        height: 30px;
        cursor: pointer;
        transition: background .2s;
        text-transform: none;
        text-decoration: none;
        text-align: left;
        outline: none;
        min-width: 240px;

        &:hover {
          border: 1px solid var(--base1);
          /* box-shadow: 0px 0px 10px rgba(0,0,0,0.15); */
        }

        svg {
            position: absolute;
            top: 21px;
            left: 16px;
            width: 20px !important;
            height: 20px !important;
        }
      }

      .search-icon {
        cursor: pointer;

        svg {
            fill: var(--dark2) !important;
            width: 24px;
            height: 24px;
            transform: translateY(3px) ;

            &:hover {
              color: var(--base1) !important;
            }
        }
      }
    }

    svg {
      font-size: 20px;
      transform: translateY(2px);

      &:hover {
        color: var(--base1);
      }
    }

    .menu-burger svg {
      transform: translateY(0px);
      font-size: 16px;
    }
  }
}

body.top {
  header#main-header {
    background: none;
    box-shadow: none;
  }
}

#responsive-nav-li {
  display: none;
}

#search-icon {
  display: none;
}

body.signed-in #app > header {
  .main-nav {
    display: none;
  }

  #responsive-nav-li {
    display: inline-block;
  }

  .sub-nav {
    #language-switch {
      display: none;
    }

    li.auth-button {
      display: none;
    }
  }
}

@media screen and (max-width: 1080px) {
  .main-nav {
    display: none;
  }

  #responsive-nav-li {
    display: inline-block;
  }

  header .sub-nav li.auth-button {
    display: none !important;
  }

  #search-button {
    display: none;
  }

  #search-icon {
    display: inline-block;
    padding: 0;
  }
}

@media screen and (max-width: 560px) {
  header#main-header .sub-nav li:nth-child(2) {
    display: none;
  }
}
</style>
