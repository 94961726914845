import {UserModelExtended, VerificationErrorResponseCodeEnum} from "@/inpriton-client/models";

export interface AuthState {
    authLoading: boolean,
    verifyLoading: boolean,
    verifyError: VerificationErrorResponseCodeEnum|null,
    signOutLoading: boolean,
    signedUp: boolean,
    signUpError: string|null,
    profile: UserModelExtended|null
}

const state: AuthState = {
    authLoading: false,
    verifyLoading: false,
    verifyError: null,
    signOutLoading: false,
    signedUp: false,
    signUpError: null,
    profile: null
}

export default state
