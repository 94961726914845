<template>
  <ul class="in-ul" :class="`type-${type}`">
    <li v-for="(item) of items" :key="item">
      <div class="symbol"></div>
      <div class="text">{{ item }}</div>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";
import {PropType} from "vue";

export default defineComponent({
  props: {
    items: {
      type: Array as () => String[]
    },
    type: { type: String as PropType<'check'>, default: 'check' }
  }
})
</script>

<style lang="scss">
@import "@/scss/vars";

.in-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 37px;

    .symbol {
      content: '';
      display: block;
      width: 15px;
      height: 8px;
      border-color: var(--base2);
      border-width: 0 0 2px 2px;
      border-style: solid;
      position: absolute;
      left: 0;
      transform: rotate(-45deg);
      top: 5px;
    }
  }
}
</style>

<docs>
```js
<in-ul :items="[
    'Item 1',
    'Item 2'
]"/>
```
</docs>