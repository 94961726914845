import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["in-section-headline", { 'has-sub': !!_ctx.sub, center: _ctx.center }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hlComponent), null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.headline), 1)
      ]),
      _: 1
    })),
    (_ctx.sub)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "sub",
          innerHTML: _ctx.sub
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}