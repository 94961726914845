<template>
  <div class="in-select" :class="{'no-placeholder': ! $props.placeholder}">
    <select :id="id" v-model="selectedValue" :disabled="disabled" :class="{ 'has-data': !! selectedValue }">
      <option :value="null" v-if=" ! disableNull" v-text="nullValue"></option>
      <option v-for="(value, key) in optionsObject" :key="key" :value="key" v-text="value"></option>
    </select>
    <div class="placeholder" v-if="$props.placeholder" v-text="$props.placeholder"></div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "@vue/runtime-core";

export default defineComponent({
  props: {
    id: {type: String, required: true},
    options: {type: Object, required: true},
    nullValue: {type: String, default: '--'},
    disableNull: {type: Boolean, default: false},
    modelValue: {required: true, validator: prop => typeof prop === 'string' || prop === null},
    disabled: {type: Boolean, default: false},
    placeholder: {type: String, default: null}
  },

  setup(props, {emit}) {
    const selectedValue = computed({
      // @ts-ignore
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value && value !== 'null' ? value : null)
    })

    const optionsObject = computed(() => {
      // @ts-ignore
      if (!Array.isArray(props.options)) {
        // @ts-ignore
        return props.options
      }

      const res: { [key: string]: string } = {}
      // @ts-ignore
      for (let key of props.options) {
        res[key] = key
      }

      return res
    })

    return {
      selectedValue,
      props,
      optionsObject
    }
  }
})
</script>

<style>

</style>

<docs>
```js
<in-select placeholder="choose" :options="{
  option1: 'Option 1',
  option2: 'Option 2',
}"/>
```
</docs>