const config: SearchOptionConfig = [
  { type: 'headline', id: 'interpreting_type', title: 'Interpreting Type', group: 'single', children: [
    { type: 'checkbox', id: 'spot', title: 'On Spot' },
    { type: 'checkbox', id: 'audio', title: 'Over Audio' },
    { type: 'checkbox', id: 'video', title: 'Over Video' }
  ]},
  { type: 'headline', id: 'interpreting_category', title: 'Interpreting Category', group: 'single', children: [
    { type: 'checkbox', id: 'judistical_issues', title: 'Judistical Issues' },
    { type: 'checkbox', id: 'police', title: 'Police' },
    { type: 'checkbox', id: 'asylum', title: 'Asylum' },
    { type: 'checkbox', id: 'medical issues', title: 'Medical Issues' },
    { type: 'checkbox', id: 'travel', title: 'Travel' },
    { type: 'checkbox', id: 'tour_guid_services', title: 'Tour Guide Services' },
    { type: 'checkbox', id: 'business', title: 'Business' },
    { type: 'checkbox', id: 'bank_issues', title: 'Bank Issues' },
    { type: 'checkbox', id: 'social_services', title: 'Social Services' },
    { type: 'checkbox', id: 'conferences_seminars', title: 'Conferences / Seminars' },
    { type: 'checkbox', id: 'examinations', title: 'Examinations' },
    { type: 'checkbox', id: 'interviews', title: 'Interviews' }
  ]},
  { type: 'separator' },
  { type: 'checkbox', id: 'only_most_qualified', title: 'Only Most Qualified', help: 'This is a help description' },
  { type: 'headline', id: 'interpreter_rating', title: 'Interpreter Rating', group: 'single', children: [
    { type: 'checkbox', id: 'stars_3', title: 'from 3 Stars', stars: 3 },
    { type: 'checkbox', id: 'stars_4', title: 'from 4 Stars', stars: 4 },
    { type: 'checkbox', id: 'stars_5', title: 'from 5 Stars', stars: 5 }
  ]},
  { type: 'headline', id: 'interpreting_qualification', title: 'Interpreting Qualification', group: 'single', children: [
    { type: 'checkbox', id: 'verified', title: 'Verified' },
    { type: 'checkbox', id: 'certified', title: 'Certified' },
    { type: 'checkbox', id: 'approved', title: 'Approved' }
  ]}
]

export default config
