import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "in-language-switch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_in_header_dropdown = _resolveComponent("in-header-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_in_header_dropdown, { id: "lang-switch" }, {
      trigger: _withCtx(() => [
        (_ctx.store.state.translationsLoading)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "spinner",
              spin: ""
            }))
          : (_openBlock(), _createBlock(_component_svg_icon, {
              key: 1,
              name: "language_outline",
              scale: 1.3
            })),
        _createTextVNode(" " + _toDisplayString(_ctx.currentLocale.display), 1)
      ]),
      dropdown: _withCtx(() => [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLanguages, (lang) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["padding-zero", { current: lang.code === _ctx.currentLocale.code }]),
              key: lang.code
            }, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "item",
                onClick: ($event: any) => (_ctx.setLocale(lang.code))
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t(`languages.${lang.code}`)), 1)
              ], 8, _hoisted_2)
            ], 2))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}