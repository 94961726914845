<template>
  <div class="in-language-switch">
    <in-header-dropdown id="lang-switch">
      <template #trigger>
        <font-awesome-icon icon="spinner" spin v-if="store.state.translationsLoading"/>
        <svg-icon name="language_outline" :scale="1.3" v-else/>
        {{ currentLocale.display }}
      </template>
      <template #dropdown>
        <ul>
          <!--li v-for="lang of availableLanguages" :key="lang.code" :class="{ current: lang.code === currentLocale.code }"-->
          <li class="padding-zero" :class="{ current: lang.code === currentLocale.code }" v-for="lang of availableLanguages" :key="lang.code">
            <a href="javascript:void(0);" class="item"  @click="setLocale(lang.code)">
            <span class="name">
              {{ t(`languages.${lang.code}`) }}
            </span>
            </a>
          </li>
        </ul>
      </template>
    </in-header-dropdown>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "@vue/runtime-core"
import {useI18n} from "vue-i18n"
import {useStore} from "vuex"
import InHeaderDropdown from "@/components/header/InHeaderDropdown.vue";
import {FrontendConfigurationLanguagesWebsite} from "@/inpriton-client/models";
import {Mutation} from "@/plugins/store";

export default defineComponent({
  components: {InHeaderDropdown},
  setup() {
    const store = useStore()
    const {t, locale} = useI18n()

    const availableLanguages = ref(store.state.languages.website);

    const currentLocale = computed<FrontendConfigurationLanguagesWebsite>(() => availableLanguages.value?.filter((l: FrontendConfigurationLanguagesWebsite) => l.code === locale.value)[0])

    return {
      t,
      store,
      locale,
      availableLanguages,
      currentLocale,
      setLocale: (code: string) => {
        store.commit(Mutation.SET_LOCALE, code)
        close()
      }
    }
  }
})
</script>

<style lang="scss">
.in-language-switch {
  margin-top: -2px;

  svg {
    height: 20px;
    fill: var(--dark2);
    transform: translateY(4px) !important;
  }

  .trigger:hover {
    svg {
      fill: var(--darker15);
    }
  }

  .padding-zero {
    padding: 0;
  }
}

body:not(.top) .in-language-switch {
  svg {
    fill: var(--dark2);
  }

  .trigger:hover svg {
    fill: var(--base1);
  }
}
</style>

<docs>
```js
<in-language-switcher/>
```
</docs>