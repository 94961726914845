/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    personType: SignUpRequestPersonTypeEnum;
    /**
     * only mandatory for personType user
     * @type {string}
     * @memberof SignUpRequest
     */
    userType?: SignUpRequestUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email: any;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: any;
}

/**
    * @export
    * @enum {string}
    */
export enum SignUpRequestPersonTypeEnum {
    User = 'user',
    Interpreter = 'interpreter'
}
/**
    * @export
    * @enum {string}
    */
export enum SignUpRequestUserTypeEnum {
    Individual = 'individual',
    Comporg = 'comporg'
}

