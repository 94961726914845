/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AccessDeniedResponse } from '../models';
import { CongratsResponse } from '../models';
import { FrontendConfiguration } from '../models';
/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundEasteregg: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/easteregg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (locale: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling getConfig.');
            }
            const localVarPath = `/config/{locale}`
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foundEasteregg(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CongratsResponse>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).foundEasteregg(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(locale: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendConfiguration>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getConfig(locale, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundEasteregg(options?: any): AxiosPromise<CongratsResponse> {
            return ConfigApiFp(configuration).foundEasteregg(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(locale: string, options?: any): AxiosPromise<FrontendConfiguration> {
            return ConfigApiFp(configuration).getConfig(locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public foundEasteregg(options?: any) {
        return ConfigApiFp(this.configuration).foundEasteregg(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} locale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfig(locale: string, options?: any) {
        return ConfigApiFp(this.configuration).getConfig(locale, options).then((request) => request(this.axios, this.basePath));
    }
}
