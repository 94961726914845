/* tslint:disable */
/* eslint-disable */
/**
 * Inpreton API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface SMSVerificationCreationFailedResponse
 */
export interface SMSVerificationCreationFailedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SMSVerificationCreationFailedResponse
     */
    success?: any;
    /**
     * 
     * @type {string}
     * @memberof SMSVerificationCreationFailedResponse
     */
    error?: any;
    /**
     * 
     * @type {string}
     * @memberof SMSVerificationCreationFailedResponse
     */
    code?: SMSVerificationCreationFailedResponseCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SMSVerificationCreationFailedResponseCodeEnum {
    VERIFIED = 'ALREADY_VERIFIED'
}

