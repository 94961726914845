import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.selectedIcon)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "material-icon",
        innerHTML: $setup.selectedIcon
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}