import {
    RegistrationFailedResponse,
    SignInRequest,
    SignUpRequest,
    VerificationErrorResponse
} from "@/inpriton-client/models";
import {AuthApi, FilesApi} from "@/inpriton-client";
import {TokenService} from "@/plugins/service/TokenService";
import {Mutation, State} from "@/plugins/store";
import emitter, {EVENT_SIGN_IN_FAILED, EVENT_SIGNED_IN, EVENT_SIGNED_OUT, EVENT_VERIFIED} from "@/plugins/emitter";
import {AxiosError} from "axios";
import {ActionContext} from "vuex";
import {AuthState} from './state';
import {AuthMutation} from "./mutations";
import jwt from 'jsonwebtoken'

type ActionCtx = ActionContext<AuthState, State>
type ActionsType = ({[name: string]: (context: ActionCtx, data: any) => any})

export const AuthAction = {
    SIGN_IN: 'SIGN_IN',
    SIGN_UP: 'SIGN_UP',
    SIGN_OUT: 'SIGN_OUT',
    VERIFY: 'VERIFY',
    CHECK_SESSION: 'CHECK_SESSION',
    DELETE_PROFILE_IMAGE: 'DELETE_PROFILE_IMAGE'
}

const PUBKEY = `-----BEGIN PUBLIC KEY-----
MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAq/+GTc1o4bkUKIw0n0oV
OAEhLFyjXBpmS+UhIGE7nIqj8sf3JWN19Mwswpi7ALTLskqXWj7kQYZqPvhujXuA
P4fZvZCmrIcW9Med9n3bZxbcq8XOZg/FI0yk47Y4d9E3Syh3Qmg1R0zIiMH/aLeS
HfO0cjyQDewDx2tiGrCe4F+ARHidz9zjKAK04vpAG6rFTepV8l4cGmATtNs0AkrJ
WThWbbFE5kaxJiWxJNFKVVEZGA5HT+cAwVaYZz9GvdmUqq2onNClqD01qL6/JhUj
NICmxGHt+wKKr1s5j8WzCmhfzdpUhL3vh+pvVTH2HcghgEa+x0QZjXm1aDxtj4z+
k13nhGL9v2hMlZT8b6VmxKO/26mm6qaZVwoVvH/dj1gzk/s1ZpnTJScfqncbMAbo
2qi/6SgMXKMuAvT+8M6jsJ5beTpw2cRWX3lPdITmUzsh5JOqfQL7cMnrk0SNDARq
/r/H9eOcW6TJqp+Sl8s6jbEihUPM2791bhrgkFr3i1+HAgMBAAE=
-----END PUBLIC KEY-----`

const actions = {
    [AuthAction.SIGN_IN]: ({ commit, rootState }, data: SignInRequest) => {
        commit(AuthMutation.AUTH_LOADING, true)

        const api = new AuthApi()

        api.login(data)
            .then(({data}) => {
                if (data && data.success) {
                    TokenService.saveToken(data.apikey)

                    /*
                    console.log('verifying token', data.jwt)
                    try {
                        const decoded = jwt.verify(data.jwt, PUBKEY)
                        console.error('jwt', decoded)
                    } catch (err) {
                        console.error('jwt verify error', err)
                    }
                    */

                    commit(AuthMutation.PROFILE, data.profile)

                    commit(Mutation.SHOW_SIGN_IN, false, {root: true})
                    commit(Mutation.SHOW_SIGN_UP, false, {root: true})

                    commit(AuthMutation.AUTH_LOADING, false)

                    emitter.emit(EVENT_SIGNED_IN)
                } else {
                    emitter.emit(EVENT_SIGN_IN_FAILED)
                    commit(AuthMutation.AUTH_LOADING, false)
                }
            })
            .catch((err: AxiosError<{success: boolean}>) => {
                emitter.emit(EVENT_SIGN_IN_FAILED)
                commit(AuthMutation.AUTH_LOADING, false)
            })
    },

    [AuthAction.SIGN_UP]: ({ commit, rootState }, data: SignUpRequest) => {
        commit(AuthMutation.AUTH_LOADING, true)

        const api = new AuthApi()

        api.register(rootState.locale, data)
            .then(({data}) => {
                commit(AuthMutation.AUTH_LOADING, false)
                commit(AuthMutation.SIGNED_UP, true)
            })
            .catch((err: AxiosError<RegistrationFailedResponse>) => {
                commit(AuthMutation.AUTH_LOADING, false)
                commit(AuthMutation.ERROR_SIGN_UP, err.response?.data.error)
            })
    },

    [AuthAction.SIGN_OUT]: async ({ commit }) => {
        commit(AuthMutation.SIGN_OUT_LOADING, true)

        try {
            const api = new AuthApi()
            await api.logout()
        } catch(err) {
            console.error('error on logout', err)
        }

        commit(AuthMutation.PROFILE, null)
        TokenService.removeToken()

        emitter.emit(EVENT_SIGNED_OUT)

        commit(AuthMutation.SIGN_OUT_LOADING, false)
    },

    [AuthAction.VERIFY]: ({ commit }, hash: string) => {
        commit(AuthMutation.VERIFY_LOADING, true)

        const api = new AuthApi()

        api.verify(hash)
            .then((data) => {
                commit(AuthMutation.SET_TOKEN, data.data.apikey)
                commit(AuthMutation.PROFILE, data.data.profile)

                commit(AuthMutation.VERIFY_LOADING, false)

                emitter.emit(EVENT_VERIFIED)
            })
            .catch((err: AxiosError<VerificationErrorResponse>) => {
                commit(AuthMutation.VERIFY_ERROR, err.response?.data.code)
                commit(AuthMutation.VERIFY_LOADING, false)
            })
    },

    [AuthAction.CHECK_SESSION] ({ commit }) {
        if (TokenService.getToken()) {
            const api = new AuthApi()

            api.check()
                .then(({data}) => {
                    commit(`${AuthMutation.PROFILE}`, data.profile)
                })
                .catch(() => {
                    commit(`${AuthMutation.SESSION_EXPIRED}`)
                })
        }
    },

    async [AuthAction.DELETE_PROFILE_IMAGE] ({ commit }) {
        const api = new FilesApi()

        const res = await api.deleteProfileImage()

        commit(AuthMutation.PROFILE, res.data.profile)
    }
} as ActionsType

export default actions
