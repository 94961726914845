import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-id", "data-trigger-id"]
const _hoisted_2 = ["data-trigger-id"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["in-header-dropdown", { open: _ctx.open }])
  }, [
    _createElementVNode("a", {
      href: "javascript:void(0);",
      class: "trigger",
      "data-id": _ctx.id,
      "data-trigger-id": _ctx.triggerId,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
      onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTouchStart && _ctx.onTouchStart(...args)))
    }, [
      _renderSlot(_ctx.$slots, "trigger")
    ], 40, _hoisted_1),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dropdown", { empty: _ctx.empty }]),
          "data-trigger-id": _ctx.triggerId,
          style: _normalizeStyle(_ctx.style)
        }, [
          (_ctx.headline)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "header",
                textContent: _toDisplayString(_ctx.headline)
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "list",
            style: _normalizeStyle(_ctx.listStyle)
          }, [
            _renderSlot(_ctx.$slots, "dropdown")
          ], 4),
          _renderSlot(_ctx.$slots, "footer")
        ], 14, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}