import {AuthState} from "./state";
import {UserModelExtended, VerificationErrorResponseCodeEnum} from "@/inpriton-client/models";
import {TokenService} from "@/plugins/service/TokenService";
import emitter, {EVENT_SIGNED_IN, EVENT_PROFILE_COMPLETED, EVENT_SESSION_EXPIRED} from "@/plugins/emitter";

export const AuthMutation = {
    SET_TOKEN: 'SET_TOKEN',
    AUTH_LOADING: 'AUTH_LOADING',
    VERIFY_LOADING: 'VERIFY_LOADING',
    VERIFY_ERROR: 'VERIFY_ERROR',
    SIGN_OUT_LOADING: 'SIGN_OUT_LOADING',
    SIGNED_UP: 'SIGNED_UP',
    PROFILE: 'PROFILE',
    ERROR_SIGN_UP: 'ERROR_SIGN_UP',
    SESSION_EXPIRED: 'SESSION_EXPIRED'
}

type MutationsType = ({[name: string]: (state: AuthState, data: any) => any})

const mutations = {
    [AuthMutation.AUTH_LOADING]: (state, value: boolean = true) => {
        state.authLoading = value
    },

    [AuthMutation.VERIFY_LOADING]: (state, value: boolean = true) => {
        state.verifyLoading = value
    },

    [AuthMutation.VERIFY_ERROR]: (state, error: VerificationErrorResponseCodeEnum|null) => {
        state.verifyError = error
    },

    [AuthMutation.SIGN_OUT_LOADING]: (state, value: boolean = true) => {
        state.signOutLoading = value
    },

    [AuthMutation.SIGNED_UP]: (state, value: boolean = true) => {
        state.signedUp = value
    },

    [AuthMutation.ERROR_SIGN_UP]: (state, error: string) => {
        state.signUpError = error
    },

    [AuthMutation.SET_TOKEN]: (state, token: string|null) => {
        if (token) {
            TokenService.saveToken(token)
        } else {
            TokenService.removeToken()
        }
    },

    [AuthMutation.PROFILE]: (state, profile: UserModelExtended|null) => {
        const wasLoggedIn = state.profile

        if (profile) {
            state.profile = profile

            if (state.profile && ! state.profile.isReady && profile.isReady) {
                emitter.emit(EVENT_PROFILE_COMPLETED)
            }

            state.profile = profile

            if ( ! wasLoggedIn) {
                emitter.emit(EVENT_SIGNED_IN)
            }
        } else {
            state.profile = null
        }
    },

    [AuthMutation.SESSION_EXPIRED]: (state, expired: boolean = true) => {
        if (expired) {
            state.profile = null

            TokenService.removeToken()

            emitter.emit(EVENT_SESSION_EXPIRED)
        }
    }
} as MutationsType

export default mutations
